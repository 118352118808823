import { api } from '.';

/**
 * Matches the list found in NotificationService/library/Models/EventType.cs
 */
export enum NotificationEventTypes {
    Other = 1,
    Lightning = 2,
    WBGT = 3,
    HEAT = 4,
    CHILL = 5,
    PRECIP = 6,
    WIND = 7,
    GUST = 8,
    AQI = 9,
    SIREN = 10,
    NWS = 11,
    PROACTIVE_FORECAST = 13
}

export const POLICY_NOTIFICATION_TYPES = [
    NotificationEventTypes.Other,
    NotificationEventTypes.Lightning,
    NotificationEventTypes.WBGT,
    NotificationEventTypes.HEAT,
    NotificationEventTypes.CHILL,
    NotificationEventTypes.PRECIP,
    NotificationEventTypes.WIND,
    NotificationEventTypes.GUST,
    NotificationEventTypes.AQI
];

export interface UserNotification {
    id: string;
    sentUtc: Date;
    owner: string;
    service: string;
    destination: string;
    message: string;
    log: string;
    timeZone: string;
    eventType: NotificationEventTypes;
    correlationId?: string;
}

export interface NotificationSettings {
    declinesNotifications: boolean;
    optOutForecast: boolean;
    lightningNotification: boolean;
    precipitationNotification: boolean;
    severeWeatherNotification: boolean;
    proactiveForecastNotification: boolean;
    notificationType: number;
    timeZone: string;
}

const notificationsApi = api.injectEndpoints({
    endpoints: builder => ({
        notifications: builder.query<UserNotification[], { startDate: string; endDate: string; count: number }>({
            query: request => ({
                url: `User/Notifications`,
                method: 'POST',
                body: request
            }),
            transformResponse: (response: { data: UserNotification[] }) => response.data
        }),
        notificationSettings: builder.query<NotificationSettings, void>({
            query: () => 'Settings/Notifications',
            transformResponse: (response: { data: NotificationSettings }) => response.data,
            providesTags: ['NotificationSettings']
        }),
        updateNotificationSettings: builder.mutation<string, NotificationSettings>({
            query: notiSet => ({
                url: 'Settings/Notifications',
                method: 'POST',
                body: notiSet
            }),
            transformResponse: (response: { data: string }) => response.data,
            invalidatesTags: ['NotificationSettings']
        })
    })
});

export const { useNotificationsQuery, useNotificationSettingsQuery, useUpdateNotificationSettingsMutation } = notificationsApi;
