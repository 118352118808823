/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import '../css/Dashboard.css';
import { Box, Container, Grid, Skeleton, useMediaQuery, Slide } from '@mui/material';
import { Typography } from '@mui/material';
import ScrollingComponent from '../Components/Dashboard/Location/Scrolling Location/ScrollingLocation';
import { Link } from '../Components/Base';
import { Tooltip, Page } from '@perry-weather/component-library';
import { CurrentConditionsCard, LightningStatus } from '../Components/Dashboard';
import {
  PWError,
  useLocationsQuery,
  useObservationsForLocationQuery,
  WeatherStationInfo,
  Location,
  useGetAirQualityForLocationQuery,
} from '../Services/API';
import Map from '../Components/Dashboard/Map/Map';
import { useAppDispatch, useAppSelector } from '../app/hooks';
import { getSelectedLocation, selectLocation, getSevereLocations } from '../features/dash/dashSlice';
import { LocationModal } from '../Components/Dashboard/Location/LocationFormModal';
import ThreatsForecast from '../Components/Dashboard/Forecast/ThreatsForecast';
import ForecastBreakdown from '../Components/Dashboard/Forecast/ForecastBreakdown';
import { Marker } from '../Assets/Marker';
import { useTheme } from '@mui/system';
import { openToast } from '../features/toast/toastSlice';
import { AlertsCard } from '../Components/Dashboard/AlertsCard';
import { NotificationsOffOutlined } from '@mui/icons-material';
import ConditionsTimeline from '../Components/Historical/ConditionsTimeline';
import { SelectedLocationCard } from '../Components/Dashboard/Location/Scrolling Location/SelectedLocationCard';
import AirQualityCard from '../Components/Dashboard/AQI/AirQualityCard';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import { TrackableEvents } from '../Components/Tracked/events';
import { withClickTracking, withRenderTracking } from '../Components/Tracked/withMixpanelTracking';

const TrackedPage = withRenderTracking(Page)(TrackableEvents.PageViewed, { page_name: 'Dashboard' });
const TrackedLink = withClickTracking(Link)(TrackableEvents.ButtonClicked, {
  button_location: 'Dashboard',
  button_feature: 'Conditions Timeline',
  button_type: 'link',
});

export function Dashboard() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const options: Intl.DateTimeFormatOptions = { weekday: 'long', year: 'numeric', month: 'short', day: 'numeric' };

  const selectedLocation = useAppSelector(getSelectedLocation);
  const severeLocations = useAppSelector(getSevereLocations);

  const { data: locations, isLoading, isError, error } = useLocationsQuery();
  const { data: observation } = useObservationsForLocationQuery(selectedLocation ? selectedLocation.id || '' : '', {
    skip: selectedLocation === undefined,
    pollingInterval: 60000,
  });

  const {
    data: airQualityData,
    isFetching: isAirQualityFetching,
    isLoading: isAirQualityLoading,
  } = useGetAirQualityForLocationQuery(selectedLocation?.id ?? skipToken);

  const [selectedImageIn, setSelectedImageIn] = useState<boolean>(true);
  const [wx, setWx] = useState<WeatherStationInfo | undefined>(undefined);

  const curDate = new Date();
  const spacing = 3;

  const handleLocationSelect = (location: Location) => {
    dispatch(selectLocation(location));
    setWx(undefined);
    setSelectedImageIn(false);
    setTimeout(() => setSelectedImageIn(true), 500);
  };

  const dispatch = useAppDispatch();
  useEffect(() => {
    if (!selectedLocation && locations && locations.length > 0) {
      dispatch(selectLocation(locations[0]));
    }
  }, [locations]);

  useEffect(() => {}, [severeLocations]);

  useEffect(() => {
    if (observation) {
      setWx(observation.weatherStation);
    }
  }, [observation]);

  let headerMsg = '';

  if (Object.keys(severeLocations).length === 0) {
    headerMsg = 'no locations';
  } else {
    headerMsg = `${Object.keys(severeLocations).length} location${Object.keys(severeLocations).length > 1 ? 's' : ''}`;
  }

  const NoLocations = () => (
    <div
      style={{
        position: 'absolute',
        left: 0,
        top: 0,
        height: '100vh',
        width: '100%',
        display: 'flex',
        alignItems: 'center',
      }}>
      <Grid
        container
        margin={1}
        alignContent='center'
        alignItems='center'
        display='flex'
        flexDirection='column'
        justifyContent='center'
        spacing={1}>
        <Grid item>
          <Marker width={85} height={85} color={theme.palette.primary.main} />
        </Grid>

        <Grid item>
          <Typography variant='h4' noWrap>
            You don't have any locations added
          </Typography>
        </Grid>

        <Grid item width='90%'>
          <Typography variant='body1'>Add a location to continue.</Typography>
        </Grid>

        <Grid item maxWidth={510}>
          <LocationModal formType='new' />
        </Grid>
      </Grid>
    </div>
  );

  const ErrorComponent = () => (
    <div
      style={{
        position: 'absolute',
        left: 0,
        top: 0,
        height: '100vh',
        width: '100%',
        display: 'flex',
        alignItems: 'center',
      }}>
      <Grid
        container
        margin={1}
        alignContent='center'
        alignItems='center'
        display='flex'
        flexDirection='column'
        justifyContent='center'
        spacing={1}>
        <Grid item>
          <Marker width={85} height={85} color={theme.palette.primary.main} />
        </Grid>

        <Grid item>
          <Typography variant='h4' noWrap>
            Problem retrieving locations
          </Typography>
        </Grid>

        <Grid item width='90%'>
          <Typography variant='body1'>Check internet connection</Typography>
        </Grid>
      </Grid>
    </div>
  );

  const hasWx = wx && wx.type > 0;
  const hasAqi = airQualityData && airQualityData.length > 0;

  const numCards = 2 + (hasWx ? 1 : 0) + (hasAqi ? 1 : 0);

  useEffect(() => {
    if (isError && error) {
      if ((error as PWError)?.data?.responseException.message) {
        dispatch(openToast({ variant: 'error', header: (error as PWError)?.data.responseException.message }));
      } else {
        dispatch(openToast({ variant: 'error', header: 'Failed To Get Locations' }));
      }
    }
  }, [isError, error, dispatch]);

  if (locations && locations.length === 0) {
    return (
      <Container maxWidth='xl'>
        <NoLocations />
      </Container>
    );
  }

  if (isError && !locations) {
    return (
      <Container maxWidth='xl'>
        <ErrorComponent />
      </Container>
    );
  }

  return (
    <Container maxWidth='xl'>
      <Grid container>
        <Grid item xs={12}>
          <Box textAlign='start'>
            {!isLoading ? (
              <>
                {!isMobile && (
                  <Typography component='span' variant='body1'>
                    {curDate.toLocaleDateString('en-US', options)}
                  </Typography>
                )}
                <Typography component='span' variant='body1'>
                  {!isMobile && ' • '}{' '}
                  {locations && (
                    <>
                      You have <b>{headerMsg}</b> in a policy warning
                    </>
                  )}
                </Typography>
              </>
            ) : (
              <Skeleton />
            )}
          </Box>
        </Grid>
      </Grid>
      <TrackedPage
        docTitle={selectedLocation ? selectedLocation.label : ''}
        loading={isLoading}
        omitContainer={true}
        title={
          selectedLocation ? (
            <Typography variant='h3' color='textPrimary' noWrap>
              {(selectedLocation.flags & 30) !== 0 && (
                <Tooltip title='Notifications are silenced for this location. Enable them by editing this location.'>
                  <NotificationsOffOutlined
                    color='error'
                    fontSize='large'
                    style={{ marginRight: '10px', verticalAlign: 'middle' }}
                  />
                </Tooltip>
              )}
              {selectedLocation.label}
            </Typography>
          ) : (
            <></>
          )
        }
        pageAction={!isLoading ? <LocationModal formType='new' /> : <></>}>
        <Box m={0}>
          {isLoading ? (
            <Skeleton variant='rectangular' height={240} width='100%' sx={{ marginBottom: '14px' }} />
          ) : (
            <Grid container spacing={spacing} marginBottom='20px'>
              <Slide direction='right' in={selectedImageIn} appear={false}>
                <Grid item xs={12} lg={4} order={{ xs: 1, lg: 0 }}>
                  <SelectedLocationCard />
                </Grid>
              </Slide>

              <Grid item xs={12} lg={8}>
                <ScrollingComponent Locations={locations} onLocationSelect={handleLocationSelect} />
              </Grid>
            </Grid>
          )}
        </Box>

        <Grid container spacing={spacing}>
          <AlertsCard />

          <Grid item xs={12} lg={numCards === 3 && hasAqi ? 12 : 6}>
            {!isLoading ? <CurrentConditionsCard /> : <Skeleton variant='rectangular' height={240} />}
          </Grid>

          <Grid item xs={12} md={hasAqi ? 6 : 12} lg={!hasWx && hasAqi ? 8 : 6}>
            {!isLoading ? <LightningStatus /> : <Skeleton variant='rectangular' height={240} />}
          </Grid>

          {hasAqi && (
            <Grid item xs={12} md={6} lg={4}>
              {!isLoading ? <AirQualityCard /> : <Skeleton variant='rectangular' height={240} />}
            </Grid>
          )}

          {hasWx && (
            <Grid item xs={12} lg={numCards === 3 ? 12 : 8}>
              {wx?.id && selectedLocation?.latitude && selectedLocation?.longitude ? (
                <ConditionsTimeline
                  stationId={wx?.id as string}
                  latitude={selectedLocation?.latitude as number}
                  longitude={selectedLocation?.longitude as number}
                  hours={3}
                  bottomTickValues={3}
                  chartMargin={{ top: 10, right: 25, bottom: 25, left: 50 }}
                  style={{ paddingLeft: 16, paddingRight: 8, paddingTop: 16, height: 180 }}>
                  <Box
                    sx={{
                      display: 'flex',
                      width: '100%',
                      justifyContent: 'flex-end',
                      paddingTop: 4,
                      paddingRight: 2,
                      paddingBottom: 2,
                    }}>
                    <TrackedLink href={`/Historical/${wx?.id as string}`} onClick={() => {}}>
                      <Typography>View All</Typography>
                    </TrackedLink>
                  </Box>
                </ConditionsTimeline>
              ) : (
                <Skeleton variant='rectangular' height={240} />
              )}
            </Grid>
          )}

          <Grid item xs={12}>
            <Grid container spacing={spacing}>
              <Grid
                minHeight={isMobile ? 600 : 400}
                item
                xs={12}
                sm={12}
                md={12}
                lg={7}
                order={{ xs: 2, sm: 2, md: 2, lg: 1, xl: 1 }}>
                {!isLoading ? <Map /> : <Skeleton variant='rectangular' height={700} />}
              </Grid>

              <Grid
                container
                item
                spacing={spacing}
                xs={12}
                sm={12}
                md={12}
                lg={5}
                order={{ xs: 1, sm: 1, md: 1, lg: 2, xl: 2 }}>
                <Grid item xs={12}>
                  {!isLoading ? <ThreatsForecast /> : <Skeleton variant='rectangular' height={338} />}
                </Grid>

                <Grid item xs={12}>
                  {!isLoading ? <ForecastBreakdown /> : <Skeleton variant='rectangular' height={338} />}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </TrackedPage>
    </Container>
  );
}

export default Dashboard;
