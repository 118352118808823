/* eslint-disable react-hooks/exhaustive-deps */
import { Box, Divider, Grid, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { Button, Modal, Select, SelectOption, Slider, Switch } from '@perry-weather/component-library';
import { SubFormContainer } from '../Base/SubFormContainer';
import EditIcon from '@mui/icons-material/Edit';
import Link from '../Base/Link';
import { useTheme } from '@mui/styles';
import {
  OWS,
  OWSAlertTime,
  OWSSettings,
  PWError,
  useOwsQuery,
  usePaStoredMessagesQuery,
  useUpdateOWSSettingsMutation,
} from '../../Services/API';
import { ConvertToUtc, formatTimeStringFromMins, getTimeDifferenceInMins } from '../../features/Time/TimeHelpers';
import HardwareStatus from './HardwareStatus';
import { openToast } from '../../features/toast/toastSlice';
import { useAppDispatch } from '../../app/hooks';
import { HardwareDisablePrompt } from './HardwareDisablePrompt';
import { withClickTracking } from '../Tracked/withMixpanelTracking';
import { TrackableEvents } from '../Tracked/events';
import mixpanel from 'mixpanel-browser';

enum DaysOfWeek {
  'Sunday' = 0,
  'Monday' = 1,
  'Tuesday' = 2,
  'Wednesday' = 3,
  'Thursday' = 4,
  'Friday' = 5,
  'Saturday' = 6,
}

function GenerateOptions() {
  let options: SelectOption[] = [];

  options.push({ text: 'All Day', value: 'allday' });

  for (let i = 0; 24 > i; i++) {
    let twelveHourValue = ((i + 11) % 12) + 1;
    let suffix = i > 11 ? 'PM' : 'AM';
    let prefix = i < 10 ? '0' + i : i;
    options.push({ text: twelveHourValue + ':00 ' + suffix, value: prefix + ':00:00' });
    options.push({ text: twelveHourValue + ':15 ' + suffix, value: prefix + ':15:00' });
    options.push({ text: twelveHourValue + ':30 ' + suffix, value: prefix + ':30:00' });
    options.push({ text: twelveHourValue + ':45 ' + suffix, value: prefix + ':45:00' });
  }

  return options;
}

interface ModalProps {
  hardwareId?: string;
  voltage: number;
  lastConnected: string;
  enabled: boolean;
  timeZone: string;
  onSubmit?: React.MouseEventHandler;
  onCancel?: React.MouseEventHandler;
}

const initialState: OWSSettings = {
  sirenId: '',
  duration: 0,
  frequency: 0,
  allClearDuration: 0,
  allClearFrequency: 0,
  alertTimes: [] as OWSAlertTime[],
};

const TrackedLink = withClickTracking(Link)(TrackableEvents.ButtonClicked, {
  button_location: 'Hardware',
  button_feature: 'Hardware Card',
  button_type: 'link',
});

export function HardwareFormModal(props: ModalProps) {
  const theme = useTheme();
  const dispatch = useAppDispatch();

  const [open, setOpen] = useState(false);
  const timeSelectionOptions = GenerateOptions();
  const { data, refetch, isFetching } = useOwsQuery(props.hardwareId ? props.hardwareId : '', { skip: !open });
  const { data: paStoredMessages } = usePaStoredMessagesQuery();
  const [
    updateOWSSettings,
    {
      data: updateResponse,
      isSuccess: updateSuccess,
      isError: updateError,
      error: updateErrorResponse,
      isLoading: updateOWSLoading,
    },
  ] = useUpdateOWSSettingsMutation();

  const [settings, setSettings] = useState<OWSSettings>(initialState);
  const [paStoredMessageOptions, setPaStoredMessageOptions] = useState<SelectOption[]>([]);

  const handleClose = (closed?: boolean) => {
    refetch();
    if (closed) initOwsSettings(data!);

    setOpen(false);
  };

  const handleOpen = () => {
    refetch();
    setOpen(true);
  };

  const handleSave = () => {
    updateOWSSettings(settings);
  };

  const handleAlertTimeChange = (time: string, id: string, type: 'start' | 'end') => {
    let alertTimes: OWSAlertTime[] = [];
    settings.alertTimes.forEach(val => alertTimes.push(Object.assign({}, val)));
    var alertTime = alertTimes.find(value => value.id === id);

    if (alertTime) {
      if (time === 'allday') {
        alertTimes[alertTimes.indexOf(alertTime)].allDay = true;
        alertTimes[alertTimes.indexOf(alertTime)].endTime = timeSelectionOptions[timeSelectionOptions.length - 1]
          .value as string;
      } else if (type === 'start') {
        alertTimes[alertTimes.indexOf(alertTime)].startTime = time;
        alertTimes[alertTimes.indexOf(alertTime)].allDay = false;
      } else if (type === 'end') alertTimes[alertTimes.indexOf(alertTime)].endTime = time;
    }

    setSettings({ ...settings, alertTimes: alertTimes });
  };

  const handleAlertTimeChangeWithObject = (time: string, alertTime: OWSAlertTime, type: 'start' | 'end') => {
    const otherTime = type === 'start' ? alertTime.endTime : alertTime.startTime;
    if (otherTime == time && time == '00:00:00') {
      handleAlertTimeChange('allday', alertTime.id, type);
      return;
    }
    handleAlertTimeChange(time, alertTime.id, type);
  };

  const handleAlertTimeEnabled = (enabled: boolean, id: string) => {
    let alertTimes: OWSAlertTime[] = [];
    settings.alertTimes.forEach(val => alertTimes.push(Object.assign({}, val)));
    var alertTime = alertTimes.find(value => value.id === id);

    if (alertTime) {
      alertTimes[alertTimes.indexOf(alertTime)].enabled = enabled;
    }

    setSettings({ ...settings, alertTimes: alertTimes });
  };

  useEffect(() => {
    if (data && open) {
      initOwsSettings(data);
    }
  }, [data, isFetching]);

  const initOwsSettings = (siren: OWS) => {
    var alertTimes = siren.alertTimes;
    var newArrTimes = alertTimes.slice().sort(function compareFn(firstEl, secondEl) {
      if (firstEl.day < secondEl.day) return -1;
      else return 1;
    });

    var settingsObj: OWSSettings = {
      sirenId: siren.id,
      duration: siren.duration,
      frequency: siren.frequency,
      allClearDuration: siren.allClearDuration,
      allClearFrequency: siren.allClearFrequency,
      postWarnMessage: siren.postWarnMessage,
      postAllClearMessage: siren.postAllClearMessage,
      alertTimes: newArrTimes,
    };

    setSettings(settingsObj);
  };

  useEffect(() => {
    if (updateSuccess && updateResponse) {
      dispatch(openToast({ variant: 'success', header: 'OWS Settings Successfully Updated' }));
      initOwsSettings(updateResponse);
      mixpanel.track(TrackableEvents.HardwareEdited, {
        hardware_id: updateResponse.id,
        hardware_name: updateResponse.locationName,
      });
      handleClose();
    } else if (updateError && updateErrorResponse) {
      dispatch(
        openToast({ variant: 'error', header: (updateErrorResponse as PWError)?.data.responseException.message })
      );
    }
  }, [updateSuccess, updateError, updateErrorResponse]);

  useEffect(() => {
    if (paStoredMessages) {
      let options: SelectOption[] = [];
      options.push({ value: '', text: 'None' });
      options.push(
        ...paStoredMessages.map(function (v) {
          return { value: v.id!, text: v.title };
        })
      );
      setPaStoredMessageOptions(options);
    }
  }, [paStoredMessages]);

  let lastConnectedMins = Math.round(getTimeDifferenceInMins(ConvertToUtc(props.lastConnected)));

  const getStartOptions = (selectOptions: SelectOption[], endTime: string): SelectOption[] => {
    if (endTime == '00:00:00') return selectOptions;

    return selectOptions.filter(option => option.value < endTime);
  };

  const getEndOptions = (selectOptions: SelectOption[], startTime: string): SelectOption[] => {
    const filteredOptions = selectOptions.filter(option => option.value > startTime);
    filteredOptions.push({ value: '00:00:00', text: '12:00 AM' });
    return filteredOptions;
  };

  const unitEnabled = props.enabled && data?.enabled;
  const enabledText = unitEnabled ? 'Enabled' : 'Disabled';
  const whatsDisabled = !data?.enabled && props.enabled ? 'OWS' : '';

  const mpExtraData = {
    hardware_id: data?.id,
    hardware_name: data?.locationName,
  };

  return (
    <Modal
      modalState={{ open: open, handleOpen: handleOpen, handleClose: handleClose }}
      modalIcon={
        props.hardwareId ? (
          <TrackedLink mpExtraData={{ ...mpExtraData, button_label: 'Edit Hardware' }}>
            <Typography variant='body1'>Edit</Typography>
            <EditIcon fontSize='small' style={{ marginLeft: 8 }} />
          </TrackedLink>
        ) : (
          <></>
        )
      }>
      <Grid container style={{ height: '100%' }}>
        <Grid item md={12} style={{ marginBottom: 24 }} display='flex' justifyContent='space-between'>
          <Typography variant='h5'>Edit {data?.locationName}</Typography>
          <Typography
            variant='subtitle1'
            style={{ color: unitEnabled ? theme.palette.success.main : theme.palette.error.main }}>
            {whatsDisabled} {enabledText}
          </Typography>
        </Grid>
        <SubFormContainer>
          <Grid item container md={12} spacing={3} marginBottom={4}>
            <Grid item xs={6}>
              <Typography variant='subtitle1'>Unit Name</Typography>
              <Typography>{data?.locationName}</Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant='subtitle1'>Time Zone</Typography>
              <Typography>{props.timeZone}</Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant='subtitle1'>Battery</Typography>
              <HardwareStatus
                gridProps={{ display: 'flex', xs: 6 }}
                tooltipText={<Typography>{Math.round(props.voltage * 10) / 10} volts (rechargeable)</Typography>}
                status={props.voltage >= 12.1 ? 'Healthy' : 'Unhealthy'}
                statusText={
                  props.voltage >= 12.1 ? (
                    <Typography ml={1}>Healthy</Typography>
                  ) : (
                    <Typography ml={1}>Unhealthy</Typography>
                  )
                }
              />
            </Grid>
            <Grid item xs={6}>
              <Typography variant='subtitle1'>Connected</Typography>
              <HardwareStatus
                gridProps={{ display: 'flex', xs: 6 }}
                tooltipText={<Typography>Last connected {formatTimeStringFromMins(lastConnectedMins)}</Typography>}
                status={lastConnectedMins < 30 ? 'Healthy' : 'Unhealthy'}
                statusText={
                  lastConnectedMins < 30 ? (
                    <Typography ml={1}>Healthy</Typography>
                  ) : (
                    <Typography ml={1}>Unhealthy</Typography>
                  )
                }
              />
            </Grid>
            <Grid item xs={6}>
              <Typography variant='subtitle1'>Radius</Typography>
              <Typography>{data?.radius} mi</Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant='subtitle1'>All Clear Timer</Typography>
              <Typography>{data?.allClearMinutes} mins</Typography>
            </Grid>
          </Grid>
        </SubFormContainer>
        <Grid item container md={12}>
          <Grid container>
            <SubFormContainer
              subheader='Lightning Alerts'
              containerDesc='Adjust the sound settings for lightning alerts through your Outdoor Warning System.'>
              <Grid container style={{ marginBottom: 8 }}>
                <Grid item xs={3} style={{ display: 'flex', justifyContent: 'start' }}>
                  <Typography variant='body1' color={theme.palette.text.secondary}>
                    Sound Duration
                  </Typography>
                </Grid>
                <Grid item xs={9}>
                  <Slider
                    onChange={value => setSettings({ ...settings, duration: value })}
                    value={settings.duration}
                    min={5}
                    max={30}
                    unit={'sec'}
                    step={5}
                    backgroundColor='linear-gradient(219.96deg, #FFE37E -38.99%, #22BACF 112%)'
                    displayValue
                  />
                </Grid>
              </Grid>

              <Grid container style={{ marginBottom: 8 }}>
                <Grid item xs={3} style={{ display: 'flex', justifyContent: 'start' }}>
                  <Typography variant='body1' color={theme.palette.text.secondary}>
                    Play Sound
                  </Typography>
                </Grid>
                <Grid item xs={9}>
                  <Slider
                    onChange={value => setSettings({ ...settings, frequency: value })}
                    value={settings.frequency}
                    min={1}
                    max={10}
                    unit={'times'}
                    backgroundColor='linear-gradient(219.96deg, #FFE37E -38.99%, #22BACF 112%)'
                    displayValue
                  />
                </Grid>
              </Grid>

              <Grid container style={{ marginBottom: 16 }}>
                <Grid item xs={3} style={{ display: 'flex', justifyContent: 'start' }}>
                  <Typography variant='body1' color={theme.palette.text.secondary}>
                    Message
                  </Typography>
                </Grid>
                <Grid item xs={9}>
                  <Select
                    value={settings.postWarnMessage}
                    options={paStoredMessageOptions}
                    placeholder='Select Saved Message'
                    onChange={value => setSettings({ ...settings, postWarnMessage: value })}
                  />
                </Grid>
              </Grid>

              <Grid container style={{ marginBottom: 8 }}>
                <Grid item xs={3} style={{ display: 'flex', justifyContent: 'start' }}>
                  <Typography variant='subtitle1'>All-Clear</Typography>
                </Grid>
              </Grid>

              <Grid container style={{ marginBottom: 8 }}>
                <Grid item xs={3} style={{ display: 'flex', justifyContent: 'start' }}>
                  <Typography variant='body1' color={theme.palette.text.secondary}>
                    Sound Duration
                  </Typography>
                </Grid>
                <Grid item xs={9}>
                  <Slider
                    onChange={value => setSettings({ ...settings, allClearDuration: value })}
                    value={settings.allClearDuration}
                    min={5}
                    max={30}
                    unit={'sec'}
                    step={5}
                    backgroundColor='linear-gradient(219.96deg, #FFE37E -38.99%, #22BACF 112%)'
                    displayValue
                  />
                </Grid>
              </Grid>

              <Grid container style={{ marginBottom: 8 }}>
                <Grid item xs={3} style={{ display: 'flex', justifyContent: 'start' }}>
                  <Typography variant='body1' color={theme.palette.text.secondary}>
                    Play Sound
                  </Typography>
                </Grid>
                <Grid item xs={9}>
                  <Slider
                    onChange={value => setSettings({ ...settings, allClearFrequency: value })}
                    value={settings.allClearFrequency}
                    min={1}
                    max={10}
                    unit={'times'}
                    backgroundColor='linear-gradient(219.96deg, #FFE37E -38.99%, #22BACF 112%)'
                    displayValue
                  />
                </Grid>
              </Grid>

              <Grid container style={{ marginBottom: 16 }}>
                <Grid item xs={3} style={{ display: 'flex', justifyContent: 'start' }}>
                  <Typography variant='body1' color={theme.palette.text.secondary}>
                    Message
                  </Typography>
                </Grid>
                <Grid item xs={9}>
                  <Select
                    value={settings.postAllClearMessage}
                    options={paStoredMessageOptions}
                    placeholder='Select Saved Message'
                    onChange={value => setSettings({ ...settings, postAllClearMessage: value })}
                  />
                </Grid>
              </Grid>
            </SubFormContainer>
          </Grid>
          <Grid container>
            <SubFormContainer
              subheader='Audible Alert Times'
              containerDesc='Your unit will be active during these times only. If lightning occurs outside of these times, your unit will not activate.'
              disableDivider>
              <Divider style={{ margin: '16px 0' }} />
              {settings.alertTimes.map(alertTime => {
                return (
                  <div>
                    <Grid container>
                      <Grid item xs={12} sm={3} style={{ display: 'flex', alignItems: 'center' }}>
                        <Typography variant='subtitle1'>{DaysOfWeek[alertTime.day]}</Typography>
                      </Grid>

                      {!alertTime.allDay ? (
                        <>
                          <Grid item xs={5} sm={3}>
                            <Select
                              isError={alertTime.startTime >= alertTime.endTime && alertTime.endTime != '00:00:00'}
                              options={getStartOptions(timeSelectionOptions, alertTime.endTime)}
                              value={alertTime.startTime}
                              disabled={!alertTime.enabled}
                              onChange={value => handleAlertTimeChangeWithObject(value, alertTime, 'start')}
                            />
                          </Grid>
                          <Grid
                            item
                            sm={1}
                            style={{
                              display: 'flex',
                              flexDirection: 'column',
                              justifyContent: 'center',
                              textAlign: 'center',
                            }}>
                            <span>&#8211;</span>
                          </Grid>
                          <Grid item xs={5} sm={3}>
                            <Select
                              isError={alertTime.startTime >= alertTime.endTime && alertTime.endTime != '00:00:00'}
                              options={getEndOptions(timeSelectionOptions, alertTime.startTime)}
                              value={alertTime.endTime}
                              disabled={!alertTime.enabled}
                              onChange={value => handleAlertTimeChangeWithObject(value, alertTime, 'end')}
                            />
                          </Grid>
                        </>
                      ) : (
                        <Grid item xs={5} sm={3}>
                          <Select
                            options={timeSelectionOptions.filter(
                              option => option.value < alertTime.endTime || option.value == 'allday'
                            )}
                            value={'allday'}
                            disabled={!alertTime.enabled}
                            onChange={value => handleAlertTimeChange(value, alertTime.id, 'start')}
                          />
                        </Grid>
                      )}

                      <Grid
                        item
                        xs={2}
                        sm={2}
                        style={{
                          display: 'flex',
                          flexDirection: 'column',
                          justifyContent: 'center',
                          alignItems: 'center',
                        }}>
                        <Box display={{ xs: 'none', md: 'block' }}>
                          <Switch
                            checked={alertTime.enabled}
                            onChange={(e, checked) => handleAlertTimeEnabled(checked, alertTime.id)}
                          />
                        </Box>
                      </Grid>
                    </Grid>
                    <Divider style={{ margin: '16px 0' }} />
                  </div>
                );
              })}
            </SubFormContainer>
          </Grid>
        </Grid>
        <Grid container style={{ paddingTop: 40 }}>
          <Grid item container spacing={1}>
            <Grid item md={5} xs={5}>
              <HardwareDisablePrompt
                enabled={data?.enabled ?? true}
                hardwareId={props.hardwareId ? parseInt(props.hardwareId) : undefined}
                hardwareName={data ? data.locationName : ''}
              />
            </Grid>
            <Grid item md={3} xs={3} justifyContent='flex-end' style={{ display: 'flex' }}>
              <Button type='clear' onClick={() => handleClose(true)}>
                <Typography variant='body1'>Cancel</Typography>
              </Button>
            </Grid>
            <Grid item md={4} xs={4}>
              <Button disabled={updateOWSLoading} onClick={() => handleSave()} type='primary'>
                <Typography variant='body1'>Save Changes</Typography>
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Modal>
  );
}
