import React, { useEffect } from 'react';
import mixpanel from 'mixpanel-browser';
import { TrackableEvents } from './events';

const DEBUG = process.env.REACT_APP_ENVIRONMENT !== 'production';

interface BaseMixpanelProps {
  mpExtraData?: Record<string, any>;
}

interface MixpanelClickProps extends BaseMixpanelProps {
  onClick?: (event: React.MouseEvent<HTMLElement>) => void;
}

// Generic type for the wrapped component's props
type WrappedComponentProps<P> = Omit<P, keyof MixpanelClickProps> & MixpanelClickProps;

export const withClickTracking =
  <P extends object>(WrappedComponent: React.ComponentType<P>) =>
  (mpEventName: TrackableEvents, mpEventData: Record<string, any>) => {
    const WithMixpanelTracking = (props: React.PropsWithChildren<WrappedComponentProps<P>>) => {
      const { mpExtraData, ...wrappedComponentProps } = props;
      const mpData = { ...mpEventData, ...mpExtraData };

      const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        if (process.env.REACT_APP_MIXPANEL_KEY && mixpanel) {
          if (DEBUG) console.log(`[Mixpanel] Click event: ${mpEventName}`, mpData);
          mixpanel.track(mpEventName, mpData);
        }

        // Call the original onClick handler if it exists
        if (props.onClick) {
          props.onClick(event);
        }
      };

      return <WrappedComponent {...(wrappedComponentProps as P)} onClick={handleClick} />;
    };

    return WithMixpanelTracking;
  };

// Generic type for the wrapped component's props
type WrappedRenderComponentProps<P> = P & BaseMixpanelProps;

export const withRenderTracking =
  <P extends object>(WrappedComponent: React.ComponentType<P>) =>
  (
    mpEventName: TrackableEvents,
    mpEventData: Record<string, any>,
    mpUnmountEventName: TrackableEvents = TrackableEvents.PageExited,
    delayEventMs: number = 2500
  ) => {
    const WithMixpanelMountTracking = (props: React.PropsWithChildren<WrappedRenderComponentProps<P>>) => {
      const { mpExtraData, ...wrappedComponentProps } = props;
      const mpData = { ...mpEventData, ...mpExtraData };

      useEffect(() => {
        if (process.env.REACT_APP_MIXPANEL_KEY && mixpanel) {
          if (DEBUG) console.log(`[Mixpanel] Render event: ${mpEventName}`, mpData);
          setTimeout(() => mixpanel.track(mpEventName, mpData), delayEventMs);
          return () => {
            mixpanel.track(mpUnmountEventName, mpData);
          };
        }
      }, []);

      return <WrappedComponent {...(wrappedComponentProps as P)} />;
    };

    return WithMixpanelMountTracking;
  };
