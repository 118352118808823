import { Typography } from '@mui/material';
import { Page } from '@perry-weather/component-library';
import { PWColors } from '../Theme/PWColors';
import { withRenderTracking } from '../Components/Tracked/withMixpanelTracking';
import { TrackableEvents } from '../Components/Tracked/events';

const TrackedPage = withRenderTracking(Page)(TrackableEvents.PageViewed, { page_name: 'Error' });

function ErrorBackground() {
  return (
    <div className='Background-Dark'>
      <div className='Background-Dark-Group'>
        <div className='Background-Dark-Gradient-1'></div>
        <div className='Background-Dark-Gradient-2'></div>
        <div className='Background-Dark-Circles-Group'>
          <div className='Background-Dark-Circles-2'></div>
          <div className='Background-Dark-Circles-1'></div>
        </div>
      </div>
      <div className='Background-Dark-Gradient'></div>
    </div>
  );
}
export default function ErrorPage() {
  return (
    <>
      <div className='App'>
        <TrackedPage title={<> </>}>
          <Typography variant='h1' color={PWColors.dark.primary[100]}>
            Way to go, you broke it.
          </Typography>
          <Typography variant='h2' color={PWColors.dark.primary[100]}>
            (Just kidding – there’s an issue loading your dashboard, but we’re working on it. Try again shortly!)
          </Typography>
        </TrackedPage>
      </div>
      <ErrorBackground />
    </>
  );
}
