import { GridColDef } from '@mui/x-data-grid';
import { useMemo, useState } from 'react';
import { DateFormatter, DateTimeFormatter } from '../../features/Time/TimeHelpers';
import { AirQualityVM } from '../../Services/API';
import Table from '../Base/BaseTable';

interface AirQualityTableProps {
  data: AirQualityVM[];
}

interface IAirQualityRow {
  id: string;
  name: string;
  customerName: string;
  status?: string;
  lastActivity?: string;
  'PM2.5'?: number;
  PM10?: number;
}

class AirQualityRow implements IAirQualityRow {
  id: string;
  name: string;
  customerName: string;
  status?: string;
  lastActivity?: string;
  'PM2.5'?: number;
  PM10?: number;

  constructor(airQualityVm: AirQualityVM) {
    this.id = airQualityVm.id;
    this.name = airQualityVm.name;
    this.customerName = airQualityVm.customerName;
    this.status = airQualityVm.status ? 'Enabled' : 'Disabled';
    this.lastActivity = airQualityVm.lastActivity
      ? `${DateFormatter(airQualityVm.lastActivity.toString())}, ${DateTimeFormatter(
          airQualityVm.lastActivity.toString()
        )}`
      : undefined;

    this['PM2.5'] = airQualityVm?.pM2_5?.nowcast;
    this['PM10'] = airQualityVm?.pM10?.nowcast;
  }
}

export function AirQualityTable(props: AirQualityTableProps) {
  const { data } = props;
  const [rows, setRows] = useState<AirQualityRow[]>([]);

  useMemo(() => {
    if (data) setRows(data.map(vm => new AirQualityRow(vm)));
  }, [data]);

  const cols: GridColDef[] = [
    { field: 'name', headerName: 'Name', flex: 0.3 },
    { field: 'PM2.5', flex: 0.1 },
    { field: 'PM10', flex: 0.1 },
    { field: 'status', headerName: 'Status', flex: 0.1 },
    { field: 'lastActivity', headerName: 'Last Activity', flex: 0.3 },
  ];

  return <Table rowHeight={54} rowSpace={8} rowsPerPageOptions={[10]} rows={rows} cols={cols} />;
}
