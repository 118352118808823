import { LinearInterpolator, _useMapControl as useMapControl } from 'react-map-gl';
import MapState from 'react-map-gl/src/utils/map-state';
import { useAppSelector } from '../../../../app/hooks';
import { getSelectedLocation } from '../../../../features/dash/dashSlice';
import { withClickTracking } from '../../../Tracked/withMixpanelTracking';
import { TrackableEvents } from '../../../Tracked/events';

const noop = () => {};

type CenterProps = {
  onClick: () => void;
};

const CenterButton = ({ onClick }: CenterProps) => (
  <button key='center-btn' className={`mapboxgl-ctrl-icon mapboxgl-ctrl-geolocate`} onClick={onClick}>
    <span className='mapboxgl-ctrl-icon' aria-hidden='true' />
  </button>
);

const TrackedCenterButton = withClickTracking(CenterButton)(TrackableEvents.ButtonClicked, {
  button_location: 'Dashboard',
  button_feature: 'Radar',
  button_type: 'button',
  button_label: 'Center Button',
});

function updateViewport(context: any, props: any, opts: any) {
  const { viewport } = context;
  const mapState = new MapState(Object.assign({}, viewport, opts));
  const viewState = Object.assign({}, mapState.getViewportProps(), new LinearInterpolator());
  viewState.transitionDuration = 300;

  const onViewportChange = props.onViewportChange || context.onViewportChange || noop;
  const onViewStateChange = props.onViewStateChange || context.onViewStateChange || noop;

  // Call new style callback
  onViewStateChange({ viewState });

  // Call old style callback
  onViewportChange(viewState);
}

export function CenterControl(props: any) {
  const { context, containerRef } = useMapControl(props);
  const selectedLocation = useAppSelector(getSelectedLocation);

  const onCenter = () => {
    updateViewport(context, props, {
      latitude: selectedLocation?.latitude,
      longitude: selectedLocation?.longitude,
      zoom: 8,
      bearing: 0,
      pitch: 0,
    });
  };

  // marginBottom 10 is not ideal, but quick fix, changing justifyContent doesn't seem to be working due to absolute positioning in parent..
  return (
    <div style={{ width: '100%', display: 'flex', flexDirection: 'column' }}>
      <div style={{ marginBottom: 10 }} className='mapboxgl-ctrl mapboxgl-ctrl-group' ref={containerRef}>
        <TrackedCenterButton
          onClick={onCenter}
          mpExtraData={{
            location_id: selectedLocation?.id,
            location_name: selectedLocation?.locationName,
            latitude: selectedLocation?.latitude,
            longitude: selectedLocation?.longitude,
          }}
        />
      </div>
    </div>
  );
}

export default CenterControl;
