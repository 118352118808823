/* eslint-disable react-hooks/exhaustive-deps */
import { Box, Grid, Link, Skeleton, Typography, useTheme } from '@mui/material';
import { useAppSelector } from '../../app/hooks';
import { getSelectedLocation } from '../../features/dash/dashSlice';
import { useLightningStatusDelay } from '../../hooks/useLightningStatusDelay';
import LightningTimerComponent from '../Animated/LightningTimerComponent';
import { Card } from '@perry-weather/component-library';
import { WarningAmberOutlined } from '@mui/icons-material';
import { ErrorBoundary } from 'react-error-boundary';

export function LightningStatus() {
  const theme = useTheme();
  const selectedLocation = useAppSelector(getSelectedLocation);
  let skeltelAnimation: boolean = false;
  const { delay, isInDelay, isLoading, isFetching, isError } = useLightningStatusDelay(selectedLocation?.id, 10000);
  const hasLR4 = selectedLocation && selectedLocation.radius4 != undefined && selectedLocation.radius4 > 0;

  return (
    <Card header='Lightning Status' scary={isInDelay}>
        <ErrorBoundary fallback={<Typography>Your lightning data is on strike. We’re in negotiations and will be back to work soon.</Typography>}>
      {isError ? (
        <Grid display='flex' justifyContent='space-around' flex='1 1 auto' alignItems='center'>
          <Grid>
            <Grid>
              <WarningAmberOutlined height={32} width={32} htmlColor={theme.palette.error.main} />
            </Grid>
            <Typography color={theme.palette.error.main} variant='h4'></Typography>
            <Typography variant='body1' style={{ color: theme.palette.error.main }}>
              Lightning status is temporarily unavailable.
            </Typography>
            <Typography variant='body1' style={{ color: theme.palette.text.primary }}>
              As we work to resolve this issue, feel free to reach out for help (
              <Link underline='none' href='mailto: support@perryweather.com'>
                support@perryweather.com
              </Link>{' '}
              or{' '}
              <Link underline='none' href='tel: 469-546-5082'>
                469-546-5082
              </Link>
              )
            </Typography>
          </Grid>
        </Grid>
      ) : (isLoading && isFetching) || delay === undefined ? (
        <Grid container wrap='nowrap'>
          <Grid item xs={4}>
            <Box display='flex' justifyContent='space-around'>
              <Skeleton animation={skeltelAnimation} variant='circular' width={75} height={75} />
              <Box alignSelf='center'>
                <Skeleton animation={skeltelAnimation} width='100%' height={50} />
                <Skeleton animation={skeltelAnimation} width='100%' height={20} />
              </Box>
            </Box>
          </Grid>
          <Grid item xs={4}>
            <Box display='flex' justifyContent='space-around'>
              <Skeleton animation={skeltelAnimation} variant='circular' width={75} height={75} />
              <Box alignSelf='center'>
                <Skeleton animation={skeltelAnimation} width='100%' height={50} />
                <Skeleton animation={skeltelAnimation} width='100%' height={20} />
              </Box>
            </Box>
          </Grid>
          <Grid item xs={4}>
            <Box display='flex' justifyContent='space-around'>
              <Skeleton animation={skeltelAnimation} variant='circular' width={75} height={75} />
              <Box alignSelf='center'>
                <Skeleton animation={skeltelAnimation} width='100%' height={50} />
                <Skeleton animation={skeltelAnimation} width='100%' height={20} />
              </Box>
            </Box>
          </Grid>
        </Grid>
      ) : (
        selectedLocation &&
        (!(
          delay.item1 === '00:00:00' &&
          delay.item2 === '00:00:00' &&
          delay.item3 === '00:00:00' &&
          delay.item4 === '00:00:00'
        ) ? (
          <Grid display='flex' justifyContent='space-around' flex='1 1 auto' alignItems='center'>
            <LightningTimerComponent
              time={delay.item1}
              allClearMinutes={selectedLocation.allClearMinutes}
              hasLR4={hasLR4}
              radiusDisplay={
                <Typography variant='body1' style={{ color: theme.palette.text.primary }}>
                  {`${0}-${selectedLocation.radius1} mi`}
                </Typography>
              }
            />
            {selectedLocation.radius2 > 0 && (
              <LightningTimerComponent
                time={delay.item2}
                allClearMinutes={selectedLocation.allClearMinutes}
                hasLR4={hasLR4}
                radiusDisplay={
                  <Typography variant='body1' style={{ color: theme.palette.text.primary }}>
                    {`${selectedLocation.radius1}-${selectedLocation.radius2} mi`}
                  </Typography>
                }
              />
            )}
            {selectedLocation.radius3 > 0 && (
              <LightningTimerComponent
                time={delay.item3}
                allClearMinutes={selectedLocation.allClearMinutes}
                hasLR4={hasLR4}
                radiusDisplay={
                  <Typography variant='body1' style={{ color: theme.palette.text.primary }}>
                    {`${selectedLocation.radius2}-${selectedLocation.radius3} mi`}
                  </Typography>
                }
              />
            )}
            {hasLR4 && (
              <LightningTimerComponent
                time={delay.item4}
                allClearMinutes={selectedLocation.allClearMinutes}
                hasLR4={hasLR4}
                radiusDisplay={
                  <Typography variant='body1' style={{ color: theme.palette.text.primary }}>
                    {`${selectedLocation.radius3}-${selectedLocation.radius4} mi`}
                  </Typography>
                }
              />
            )}
          </Grid>
        ) : (
          <Grid display='flex' justifyContent='space-around' flex='1 1 auto' alignItems='center'>
            <LightningTimerComponent
              time={delay.item1}
              allClearMinutes={selectedLocation.allClearMinutes}
              radiusDisplay={
                <>
                  <Typography variant='body1' style={{ color: theme.palette.text.primary }}>
                    {`${0}-${selectedLocation.radius1} mi`}
                  </Typography>
                  {selectedLocation.radius2 > 0 && (
                    <Typography variant='body1' style={{ color: theme.palette.text.primary }}>
                      {`${selectedLocation.radius1}-${selectedLocation.radius2} mi`}
                    </Typography>
                  )}
                  {selectedLocation.radius3 > 0 && (
                    <Typography variant='body1' style={{ color: theme.palette.text.primary }}>
                      {`${selectedLocation.radius2}-${selectedLocation.radius3} mi`}
                    </Typography>
                  )}
                  {selectedLocation.radius4 != undefined && selectedLocation.radius4 > 0 && (
                    <Typography variant='body1' style={{ color: theme.palette.text.primary }}>
                      {`${selectedLocation.radius3}-${selectedLocation.radius4} mi`}
                    </Typography>
                  )}
                </>
              }
            />
          </Grid>
        ))
      )}
        </ErrorBoundary>
    </Card>
  );
}

export default LightningStatus;
