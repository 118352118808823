import MasterMap from '../../../../Assets/masterMap.svg';
import { PWColors } from '../../../../Theme/PWColors';
import { TrackableEvents } from '../../../Tracked/events';
import { withClickTracking } from '../../../Tracked/withMixpanelTracking';

type MasterMapControlProps = {
  onClick: () => void;
  isActive: boolean;
};

const MasterMapControl = ({ onClick, isActive }: MasterMapControlProps) => (
  <div style={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: '100%' }}>
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: '100%',
        marginBottom: 10,
        backgroundColor: isActive ? PWColors.dark.primary[500] : '#fff',
      }}
      className='mapboxgl-ctrl mapboxgl-ctrl-group'>
      <button className={`mapboxgl-ctrl-icon`} type='button' onClick={onClick}>
        <img src={MasterMap} alt='Master Map' style={{ width: '80%', height: '80%' }} />
      </button>
    </div>
  </div>
);

export const TrackedMasterMapControl = withClickTracking(MasterMapControl)(TrackableEvents.ButtonClicked, {
  button_location: 'Dashboard',
  button_feature: 'Radar',
  button_type: 'button',
  button_label: 'Master Map Button',
});

export default TrackedMasterMapControl;
