/* eslint-disable react-hooks/exhaustive-deps */
import { InfoOutlined, Lock } from '@mui/icons-material';
import { Accordion, AccordionDetails, AccordionSummary, Box, Grid, Typography, useTheme } from '@mui/material';
import { useAuth } from 'oidc-react';
import React, { memo, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { openToast } from '../../features/toast/toastSlice';
import {
  NotificationAction,
  Policy,
  PolicyErrorsModel,
  ModelStateErrorResponse,
  useAddPolicyMutation,
  useDeletePolicyMutation,
  usePoliciesQuery,
  useSitesQuery,
  useUpdatePolicyMutation,
  useSirenQuery,
} from '../../Services/API';
import { getDefaultPolicyMessage } from '../../utils/utils';
import { SubFormContainer, InputRow, Label } from '../Base';
import {
  Button,
  MultiSelect,
  SelectOption,
  Checkbox,
  TextField,
  TextArea,
  Tooltip,
  Modal,
  ModalState,
} from '@perry-weather/component-library';
import { DeletionDialog } from '../Base/DeletionDialog';
import { PolicyTypeSelect } from './PolicyTypeSelect';
import { PolicyUpdatePrompt } from './PolicyUpdatePrompt';
import { withClickTracking } from '../Tracked/withMixpanelTracking';
import { TrackableEvents } from '../Tracked/events';
import mixpanel from 'mixpanel-browser';

interface PolicyModalProps {
  modalState: ModalState;
  policy: Policy;
}

const TrackedButton = withClickTracking(Button)(TrackableEvents.ButtonClicked, {
  button_location: 'Policies',
  button_feature: 'Policy Modal',
  button_type: 'button',
});

export const PolicyModal = memo(function PolicyModal(props: PolicyModalProps) {
  const theme = useTheme();
  const { modalState, policy } = props;
  const { userData: user } = useAuth();

  const dispatch = useDispatch();
  const [editedPolicy, setEditedPolicy] = useState<Policy>(policy);
  const [policyErrors, setPolicyErrors] = useState<PolicyErrorsModel>({});

  const [
    updatePolicy,
    {
      data: updatePolicyResponse,
      isSuccess: isUpdatePolicySuccess,
      isError: isUpdatePolicyError,
      error: updatePolicyError,
      isLoading: updatePolicyLoading,
    },
  ] = useUpdatePolicyMutation();
  const [
    addPolicy,
    {
      data: addPolicyResponse,
      isSuccess: isAddPolicySuccess,
      isError: isAddPolicyError,
      error: addPolicyError,
      isLoading: addPolicyLoading,
    },
  ] = useAddPolicyMutation();
  const [
    deletePolicy,
    {
      data: deletePolicyResponse,
      isSuccess: isDeletePolicySuccess,
      isError: isDeletePolicyError,
      error: deletePolicyError,
      isLoading: deletePolicyLoading,
    },
  ] = useDeletePolicyMutation();
  const { data: customerSirens } = useSirenQuery();
  const { refetch: refetchPolicies } = usePoliciesQuery();
  const { data: sites, refetch: refetchSites } = useSitesQuery(undefined, {
    skip: !(user?.profile?.Role === 'Admin' || user?.profile?.Role === 'Super Admin'),
  });

  const [promptOpen, setPromptOpen] = useState(false);

  const handleClose = () => {
    modalState.handleClose();
    setPromptOpen(false);
    setPolicyErrors({});
  };

  const handleSave = () => {
    if (editedPolicy.id === '') {
      addPolicy({ ...editedPolicy });
    } else {
      if (
        editedPolicy.policyTypeShortName === 'LR1' &&
        customerSirens &&
        customerSirens?.length > 0 &&
        editedPolicy.threshold !== policy.threshold
      ) {
        setPromptOpen(true);
      } else {
        updatePolicy({ ...editedPolicy });
      }
    }
  };

  const handleUpdatePolicy = () => {
    updatePolicy({ ...editedPolicy });
  };

  const handleAddNotiAction = (isAllClear: boolean) => {
    if (editedPolicy) {
      var notiActions = [];
      notiActions.push(...editedPolicy.notificationActions);
      notiActions.push({ id: '', triggerId: editedPolicy.id, allClear: isAllClear, message: '' });

      setEditedPolicy({ ...editedPolicy, notificationActions: notiActions });
    }
  };

  const handleUpdateNotiAction = (message: string, isAllClear: boolean) => {
    if (editedPolicy) {
      var notiActions = [];

      var notiAction = editedPolicy.notificationActions.find(x => x.allClear === isAllClear);
      if (notiAction) {
        notiActions.push({ ...notiAction, message: message });
      }

      var otherNotiAction = editedPolicy.notificationActions.find(x => x.allClear === !isAllClear);
      if (otherNotiAction) {
        notiActions.push({ ...otherNotiAction });
      }
      setEditedPolicy({ ...editedPolicy, notificationActions: notiActions });
    }
  };

  const handleRemoveNotiAction = (isAllClear: boolean) => {
    if (editedPolicy) {
      let notiActions: NotificationAction[] = [];
      if (isAllClear)
        notiActions = editedPolicy.notificationActions?.filter(x => {
          return x.allClear !== isAllClear;
        });
      setEditedPolicy({ ...editedPolicy, notificationActions: notiActions });
    }
  };

  const hasNotiAction = (isAllClear: boolean) => {
    if (editedPolicy) {
      var notiAction = editedPolicy.notificationActions?.find(x => x.allClear === isAllClear);
      return notiAction !== undefined;
    }

    return false;
  };

  const getNotiAction = (isAllClear: boolean) => {
    if (editedPolicy) {
      var notiAction = editedPolicy.notificationActions?.find(x => x.allClear === isAllClear);
      return notiAction;
    }
  };

  useEffect(() => {
    if (isUpdatePolicySuccess) {
      const { id, policyTypeName, threshold, allClearMinutes, sites } = updatePolicyResponse;
      mixpanel.track(TrackableEvents.PolicyEdited, {
        policy_id: id,
        policy_name: policyTypeName,
        notify_at_value: threshold,
        clear_threshold_time: allClearMinutes,
        user_groups_applied: sites,
      });
      dispatch(openToast({ variant: 'success', header: 'Updated' }));
      refetchPolicies();
      refetchSites();
      handleClose();
    } else if (isUpdatePolicyError) {
      let errors = (updatePolicyError as ModelStateErrorResponse)?.data?.responseException?.errors as PolicyErrorsModel;
      if (errors) {
        if (errors.ServerErrors && errors.ServerErrors.length > 0)
          dispatch(openToast({ variant: 'error', header: errors.ServerErrors[0] }));
        else dispatch(openToast({ variant: 'error', header: 'Error updating policy' }));

        setPolicyErrors({ ...errors });
      }
    }
  }, [updatePolicyResponse, isUpdatePolicySuccess, isUpdatePolicyError, updatePolicyError]);

  useEffect(() => {
    if (isAddPolicySuccess) {
      const { id, policyTypeName, threshold, allClearMinutes, sites } = addPolicyResponse;
      mixpanel.track(TrackableEvents.PolicyCreated, {
        policy_id: id,
        policy_name: policyTypeName,
        notify_at_value: threshold,
        clear_threshold_time: allClearMinutes,
        user_groups_applied: sites,
      });
      dispatch(openToast({ variant: 'success', header: 'Saved' }));
      refetchPolicies();
      refetchSites();
      handleClose();
    } else if (isAddPolicyError) {
      let errors = (addPolicyError as ModelStateErrorResponse)?.data?.responseException?.errors as PolicyErrorsModel;
      if (errors) {
        if (errors.ServerErrors && errors.ServerErrors.length > 0)
          dispatch(openToast({ variant: 'error', header: errors.ServerErrors[0] }));
        else dispatch(openToast({ variant: 'error', header: 'Error adding policy' }));

        setPolicyErrors({ ...errors });
      }
    }
  }, [addPolicyResponse, isAddPolicySuccess, isAddPolicyError, addPolicyError]);

  useEffect(() => {
    if (isDeletePolicySuccess) {
      const { id, policyTypeName } = editedPolicy;
      mixpanel.track(TrackableEvents.PolicyDeleted, {
        policy_id: id,
        policy_name: policyTypeName,
      });
      dispatch(openToast({ variant: 'success', header: 'Deleted' }));
      refetchPolicies();
      refetchSites();
      handleClose();
    } else if (isDeletePolicyError) {
      let errors = (deletePolicyError as ModelStateErrorResponse)?.data?.responseException?.errors as PolicyErrorsModel;
      if (errors) {
        if (errors.ServerErrors && errors.ServerErrors.length > 0)
          dispatch(openToast({ variant: 'error', header: errors.ServerErrors[0] }));
        else dispatch(openToast({ variant: 'error', header: 'Error deleting policy' }));
      }
    }
  }, [deletePolicyResponse, isDeletePolicySuccess, isDeletePolicyError, deletePolicyError]);

  useEffect(() => {
    if (policy) {
      setEditedPolicy(policy);
    }
  }, [policy]);

  return (
    <Modal modalState={modalState}>
      <Grid container>
        <Grid item xs={12} marginBottom='8px'>
          <Typography variant='h5'>
            {' '}
            {editedPolicy.id === '' ? 'New Policy' : `Editing ${editedPolicy.policyTypeName} Policy`}{' '}
          </Typography>
        </Grid>
        <Grid item xs={12} marginBottom='12px'>
          <Typography variant='body1' color='textSecondary'>
            {' '}
            {(editedPolicy.id === '' ? 'Choose' : 'Update') +
              ' the notification settings you would like to enforce.'}{' '}
          </Typography>
        </Grid>
        <SubFormContainer>
          <InputRow label='Policy *'>
            <PolicyTypeSelect
              placeholder='Select a Policy'
              disabled={editedPolicy.id !== '' && editedPolicy.policyType !== 0}
              value={editedPolicy.policyType}
              onSelect={p => {
                setEditedPolicy({
                  ...editedPolicy,
                  threshold: parseFloat(editedPolicy.threshold.toFixed(p.precision)),
                  isDesc: p.isDesc,
                  policyType: p.id,
                  policyTypeShortName: p.name,
                  policyTypeName: p.displayName,
                  unit: p.unit,
                  precision: p.precision,
                  limit: p.limit,
                  minAllClear: p.minAllClear,
                  allClearMinutes: p.minAllClear,
                });
              }}
            />
          </InputRow>
          <InputRow
            label='Notify At *'
            labelSize={(editedPolicy.unit || '').length > 3 ? 7 : 9}
            fieldSize={(editedPolicy.unit || '').length > 3 ? 5 : 3}>
            <TextField
              helperText={policyErrors?.Threshold ? policyErrors.Threshold[0] : undefined}
              isError={policyErrors?.Threshold && policyErrors.Threshold.length > 0}
              type='number'
              value={editedPolicy.threshold.toString()}
              onChange={e =>
                setEditedPolicy({
                  ...editedPolicy,
                  threshold: e.target.value
                    ? parseFloat(parseFloat(e.target.value).toFixed(editedPolicy.precision))
                    : 0,
                })
              }
              endAdornment={
                <Typography variant='overline' color='textSecondary'>
                  {editedPolicy.unit || ''}
                </Typography>
              }
            />
          </InputRow>
          <InputRow
            label='All Clear *'
            labelSize={9}
            fieldSize={3}
            labelSuffix={
              <Tooltip
                title={`Expire policy warning when ${editedPolicy.policyTypeName} remains ${
                  editedPolicy.isDesc ? 'above' : 'below'
                } ${editedPolicy.threshold} for this period of time.`}>
                <div
                  style={{ padding: '0 0 0 9px', display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                  <InfoOutlined fontSize='small' htmlColor={theme.palette.primary.main} />
                </div>
              </Tooltip>
            }>
            <TextField
              helperText={policyErrors?.AllClearMinutes ? policyErrors.AllClearMinutes[0] : undefined}
              isError={policyErrors?.AllClearMinutes && policyErrors.AllClearMinutes.length > 0}
              type='number'
              value={editedPolicy.allClearMinutes.toString()}
              onChange={e =>
                setEditedPolicy({
                  ...editedPolicy,
                  allClearMinutes: e.target.value ? parseInt(e.target.value) : 0,
                })
              }
              endAdornment={
                <Typography variant='overline' color='textSecondary'>
                  min
                </Typography>
              }
            />
          </InputRow>
          <InputRow label='Apply to *'>
            <>
              <MultiSelect
                disabled={editedPolicy.allGroups}
                isError={policyErrors?.Sites && policyErrors.Sites.length > 0}
                placeholder='Select Group(s) to apply policy'
                options={sites?.map<SelectOption>(site => ({ value: site.id, text: site.name })) || []}
                value={editedPolicy.sites}
                onChange={values => setEditedPolicy({ ...editedPolicy, sites: values.map(value => parseInt(value)) })}
              />
              <Grid container justifyContent='flex-end'>
                <Checkbox
                  checked={editedPolicy.allGroups}
                  onChange={e => setEditedPolicy({ ...editedPolicy, allGroups: e.target.checked })}
                />
                <Typography variant='caption' display='flex' flexDirection='column' justifyContent='center'>
                  Apply to All Groups
                </Typography>
              </Grid>
            </>
          </InputRow>
        </SubFormContainer>

        <Grid container marginBottom='16px'>
          <SubFormContainer subheader='Actions'>
            <Accordion elevation={0} expanded={editedPolicy.notificationActions.length > 0}>
              <AccordionSummary
                onClick={() => {
                  editedPolicy.notificationActions.length > 0
                    ? handleRemoveNotiAction(false)
                    : handleAddNotiAction(false);
                }}>
                <Checkbox checked={editedPolicy.notificationActions.length > 0} />
                <Typography display='flex' flexDirection='column' justifyContent='center'>
                  Notify Users
                </Typography>
                <Tooltip title='Notify users when this policy warning goes into effect. Users will receive a text message or push notification depending on their personal settings.'>
                  <div
                    style={{
                      padding: '0 0 0 9px',
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'center',
                    }}>
                    <InfoOutlined fontSize='small' htmlColor={theme.palette.primary.main} />
                  </div>
                </Tooltip>
              </AccordionSummary>
              <AccordionDetails style={{ padding: '0 16px' }}>
                <Grid container marginBottom='16px'>
                  <Grid item container xs={4}>
                    <Box display='flex' flexDirection='column' justifyContent='center' padding='0 9px'>
                      <Lock htmlColor={theme.palette.text.disabled} />
                    </Box>
                    <Label text='Message' />
                  </Grid>
                  <Grid item xs={8}>
                    <TextArea
                      value={getDefaultPolicyMessage(editedPolicy).triggerMessage}
                      disabled
                      sx={{
                        '&.MuiInputBase-root, textarea': {
                          WebkitTextFillColor: `${theme.palette.text.primary} !important`,
                          color: theme.palette.text.primary,
                        },
                      }}
                    />
                  </Grid>
                </Grid>
                <Grid container style={{ paddingLeft: 9 }}>
                  <Grid item container xs={4}>
                    <Label text='Additional Message' />

                    <Tooltip
                      title={
                        <Box padding='0 16px'>
                          {'We suggest providing specific, actionable instructions for your users here. Examples:'}
                          <ul style={{ listStyle: 'initial' }}>
                            <li>“Return to clubhouse immediately”</li>
                            <li>“Remove protective equipment”</li>
                            <li>“Begin implementing 20 mins of breaks per hour of activity"</li>
                          </ul>
                        </Box>
                      }>
                      <Typography
                        component='span'
                        variant='body2'
                        color='textSecondary'
                        style={{ textDecoration: 'underline dotted' }}>
                        {'Added to the end of the message above.'}
                      </Typography>
                    </Tooltip>
                    {/*  */}
                  </Grid>
                  <Grid item xs={8}>
                    <TextArea
                      value={getNotiAction(false)?.message}
                      onChange={e => handleUpdateNotiAction(e.target.value, false)}
                    />
                  </Grid>
                </Grid>
              </AccordionDetails>
            </Accordion>
            {hasNotiAction(false) && (
              <Accordion elevation={0} expanded={editedPolicy.notificationActions.length > 1}>
                <AccordionSummary
                  onClick={() => {
                    editedPolicy.notificationActions.length > 1
                      ? handleRemoveNotiAction(true)
                      : handleAddNotiAction(true);
                  }}>
                  <Checkbox checked={editedPolicy.notificationActions.length > 1} />
                  <Typography display='flex' flexDirection='column' justifyContent='center'>
                    Notify On All Clear
                  </Typography>
                  <Tooltip title='Notify users when this policy warning expires according to the All Clear setting above. Users will receive a text message or push notification depending on their personal settings.'>
                    <div
                      style={{
                        padding: '0 0 0 9px',
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                      }}>
                      <InfoOutlined fontSize='small' htmlColor={theme.palette.primary.main} />
                    </div>
                  </Tooltip>
                </AccordionSummary>
                <AccordionDetails>
                  <Grid container marginBottom='16px'>
                    <Grid item container xs={4}>
                      <Box display='flex' flexDirection='column' justifyContent='center' padding='0 9px 0 0'>
                        <Lock htmlColor={theme.palette.text.disabled} />
                      </Box>
                      <Label text='Message' />
                    </Grid>
                    <Grid item xs={8}>
                      <TextArea
                        value={getDefaultPolicyMessage(editedPolicy).allClearMessage}
                        disabled
                        sx={{
                          '&.MuiInputBase-root, textarea': {
                            WebkitTextFillColor: `${theme.palette.text.primary} !important`,
                            color: theme.palette.text.primary,
                          },
                        }}
                      />
                    </Grid>
                  </Grid>
                  <Grid container>
                    <Grid item container xs={4}>
                      <Label text='Additional Message' />
                    </Grid>
                    <Grid item xs={8}>
                      <TextArea
                        value={getNotiAction(true)?.message}
                        onChange={e => handleUpdateNotiAction(e.target.value, true)}
                      />
                    </Grid>
                  </Grid>
                </AccordionDetails>
              </Accordion>
            )}
            <Accordion elevation={0} disabled>
              <AccordionSummary>
                <Checkbox checked={true} />
                <Typography display='flex' flexDirection='column' justifyContent='center'>
                  Show on Dashboard
                </Typography>
              </AccordionSummary>
            </Accordion>
          </SubFormContainer>
        </Grid>
        <SubFormContainer disableDivider>
          <Grid container display='flex' justifyContent='flex-end' spacing={1}>
            {editedPolicy.id !== '' && (
              <Grid item>
                <DeletionDialog
                  button={<Button type='delete'>Delete </Button>}
                  item='Policy'
                  identifier={editedPolicy.policyTypeName}
                  onRemove={e => deletePolicy(editedPolicy.id)}
                />
              </Grid>
            )}
            <Grid>
              <PolicyUpdatePrompt
                updatePolicy={handleUpdatePolicy}
                modalState={{
                  open: promptOpen,
                  handleOpen: () => setPromptOpen(true),
                  handleClose: () => setPromptOpen(false),
                }}
                customerSirens={customerSirens}
              />
            </Grid>
            <Grid item>
              <Button type='clear' onClick={e => handleClose()}>
                Cancel
              </Button>
            </Grid>
            <Grid item>
              <TrackedButton
                mpExtraData={{
                  button_label: 'Save',
                  policy_id: editedPolicy.id,
                  policy_name: editedPolicy.policyTypeName,
                }}
                disabled={addPolicyLoading || updatePolicyLoading}
                onClick={e => handleSave()}>
                Save
              </TrackedButton>
            </Grid>
          </Grid>
        </SubFormContainer>
      </Grid>
    </Modal>
  );
});
