import LayersOutlinedIcon from '@mui/icons-material/LayersOutlined';
import React from 'react';
import { Box, Grow, styled, ToggleButton, ToggleButtonGroup, Typography } from '@mui/material';
import BaseControl from './BaseControl';
import { getOverlays, setOverlays, overlayTypes } from '../../../../features/map/mapSlice';
import { useAppDispatch, useAppSelector } from '../../../../app/hooks';
import { PWColors } from '../../../../Theme/PWColors';
import { User } from 'oidc-react';
import { withClickTracking } from '../../../Tracked/withMixpanelTracking';
import { TrackableEvents } from '../../../Tracked/events';

const TrackedToggleButton = withClickTracking(ToggleButton)(TrackableEvents.ButtonClicked, {
  button_location: 'Dashboard',
  button_feature: 'Radar',
  button_type: 'dropdown',
});

const StyledToggleButton = styled(TrackedToggleButton)(() => ({
  marginTop: 2,
  marginBottom: 2,
  border: 'none',
  borderRadius: '8px',
  '&:hover': {
    backgroundColor: '#E9E9E9',
  },
}));

interface LayerControlProps {
  onClick?: React.MouseEventHandler;
  show: boolean;
  user?: User | null;
}

export function LayerControl(props: LayerControlProps) {
  const { onClick, show, user } = props;

  const dispatch = useAppDispatch();

  var chosenLayers = useAppSelector(getOverlays);

  const handleLayerChange = (event: React.MouseEvent<HTMLElement>, newLayers: overlayTypes[]) => {
    dispatch(setOverlays(newLayers));
  };

  return (
    <Box>
      <Grow in={show}>
        <div className='overlayMenu right'>
          <ToggleButtonGroup color='secondary' orientation='vertical' value={chosenLayers} onChange={handleLayerChange}>
            <StyledToggleButton value='tropical-models' mpExtraData={{ button_label: 'Tropical Models' }}>
              <Typography color={PWColors.dark.base['800']}>Tropical Models</Typography>
            </StyledToggleButton>
            <StyledToggleButton value='tropical-forecast' mpExtraData={{ button_label: 'Tropical Forecast' }}>
              <Typography color={PWColors.dark.base['800']}>Tropical Forecast</Typography>
            </StyledToggleButton>
            <StyledToggleButton value='surface-analysis' mpExtraData={{ button_label: 'Surface Analysis' }}>
              <Typography color={PWColors.dark.base['800']}>Surface Analysis</Typography>
            </StyledToggleButton>
            <StyledToggleButton value='storm-vectors' mpExtraData={{ button_label: 'Storm Vectors' }}>
              <Typography color={PWColors.dark.base['800']}>Storm Cells</Typography>
            </StyledToggleButton>
            <StyledToggleButton value='nws-alerts' mpExtraData={{ button_label: 'NWS Alerts' }}>
              <Typography color={PWColors.dark.base['800']}>NWS Alerts</Typography>
            </StyledToggleButton>
            <StyledToggleButton value='road-conditions' mpExtraData={{ button_label: 'Road Conditions' }}>
              <Typography color={PWColors.dark.base['800']}>Road Conditions</Typography>
            </StyledToggleButton>
            <StyledToggleButton value='radar' mpExtraData={{ button_label: 'Radar' }}>
              <Typography color={PWColors.dark.base['800']}>Radar</Typography>
            </StyledToggleButton>
            <StyledToggleButton value='lightning' mpExtraData={{ button_label: 'Lightning' }}>
              <Typography color={PWColors.dark.base['800']}>Lightning</Typography>
            </StyledToggleButton>
          </ToggleButtonGroup>
        </div>
      </Grow>
      <BaseControl onClick={onClick} label='Layer Options'>
        <LayersOutlinedIcon />
      </BaseControl>
    </Box>
  );
}

export default LayerControl;
