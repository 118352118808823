import WxImageModal from '../../../WeatherStation/WxImageModal';
import { WxImage as WxComponent } from '../../../Base';
import { LocationModal } from '../LocationFormModal';
import { IconButton, styled, Typography, useMediaQuery, useTheme } from '@mui/material';
import { ConvertToUtc, formatTimeStringFromMins, getTimeDifferenceInMins } from '../../../../features/Time/TimeHelpers';
import { useEffect, useMemo, useState } from 'react';
import {
  useCachedPolicyAlertsForLocationQuery,
  useNwsAlertsQuery,
  useObservationsForLocationQuery,
  useWXImageLastModifiedQuery,
} from '../../../../Services/API';
import { Expand } from '../../../../Assets';
import { useAppDispatch, useAppSelector } from '../../../../app/hooks';
import { addSevereLocation, getSelectedLocation, removeSevereLocation } from '../../../../features/dash/dashSlice';
import { PolicyTypeName } from '../../../../Constants/Constants';
import { useLightningStatusDelay } from '../../../../hooks/useLightningStatusDelay';
import { withClickTracking } from '../../../Tracked/withMixpanelTracking';
import { TrackableEvents } from '../../../Tracked/events';

/// Could use an sx property below, but this is a good example for using styled components.
const WxImageContainer = styled('div')(({ theme }) => ({
  // height: '15em',
  position: 'relative',
  height: '100%',
  borderRadius: '10px',
  overflow: 'hidden',
  border: '2px solid',
  borderColor: theme.palette.action.selected,
  maxHeight: 240,
  minHeight: 225,
}));

const WxImage = styled('img')({
  width: '100%',
  height: '100%',
  objectFit: 'cover',
});

const WxImageDetails = styled('div')(({ theme }) => ({
  overflow: 'hidden',
  position: 'absolute',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  padding: 8,
  width: '100%',
  height: 40,
  background: theme.palette.background.default + 'A0',
}));

const TrackedExpandImageButton = withClickTracking(IconButton)(TrackableEvents.ButtonClicked, {
  button_location: 'Dashboard',
  button_feature: 'Selected Location',
  button_label: 'Expand Image',
  button_type: 'icon',
});

export function SelectedLocationCard() {
  const dispatch = useAppDispatch();
  const theme = useTheme();

  const selectedLocation = useAppSelector(getSelectedLocation);

  const {
    data: observation,
    isError,
    isFetching,
  } = useObservationsForLocationQuery(selectedLocation ? selectedLocation.id || '' : '', {
    skip: selectedLocation === undefined,
  });

  const wx = observation?.weatherStation;
  const curDate = new Date();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const [expandModalOpen, setExpandModalOpen] = useState(false);
  const [imageSrc, setImageSrc] = useState<any>(null);

  const { data: imageLastModified } = useWXImageLastModifiedQuery(wx?.id || '', {
    skip: selectedLocation === undefined || !wx?.id,
    pollingInterval: 300000,
  });

  const { data: nwsAlerts } = useNwsAlertsQuery(selectedLocation ? selectedLocation.id || '' : '', {
    skip: selectedLocation === undefined,
  });
  const { data: policyViolations } = useCachedPolicyAlertsForLocationQuery(selectedLocation?.id || '', {
    skip: selectedLocation?.id === undefined,
  });
  const lastUpdatedMins =
    wx && imageLastModified ? Math.round(getTimeDifferenceInMins(ConvertToUtc(imageLastModified))) : undefined;

  useLightningStatusDelay(selectedLocation?.id, 10000);

  useEffect(() => {
    if (selectedLocation && selectedLocation.id && nwsAlerts) {
      // NWS Bulletin
      if (nwsAlerts.length > 0)
        dispatch(
          addSevereLocation({
            locationId: selectedLocation.id,
            threatType: { shortName: 'NWS', longName: [...nwsAlerts.map(x => x.Event)] },
          })
        );
      else
        dispatch(
          removeSevereLocation({ locationId: selectedLocation.id, threatType: { shortName: 'NWS', longName: [] } })
        );
    }
  }, [nwsAlerts]);

  useEffect(() => {
    if (policyViolations && selectedLocation && selectedLocation.id !== undefined) {
      const alertSuffix = ' Policy Warning';
      const policyTypes: PolicyTypeName[] = [
        'WBGT',
        'WIND',
        'GUST',
        'AQI',
        'CHILL',
        'HEAT',
        'PRECIP',
        'AQI-PM2.5',
        'AQI-PM10',
        'AQI-O3',
        'AQI-MAX'
      ];

      policyTypes.forEach(policyType => {
        let exists = policyViolations.find(x => x.policyName === policyType);
        if (exists)
          dispatch(
            addSevereLocation({
              locationId: selectedLocation.id!,
              threatType: { shortName: policyType, longName: [exists.policyDisplayName + alertSuffix] },
            })
          );
        else
          dispatch(
            removeSevereLocation({
              locationId: selectedLocation.id!,
              threatType: { shortName: policyType, longName: [] },
            })
          );
      });
    }
  }, [policyViolations]);

  useEffect(() => {
    var weatherStationInfo = observation?.weatherStation;
    setImageSrc(null);
    // console.debug(`Error: ${isError}`)
    // console.debug(`Fetching: ${isFetching}`)
    if (weatherStationInfo && !isError && !isFetching)
      setImageSrc(`https://functionstestin9947.blob.core.windows.net/images/${weatherStationInfo!.id}.jpg?${curDate}`);
  }, [observation, isError, isFetching]);

  return (
    <WxImageContainer>
      <div style={{ position: 'absolute', bottom: 0, left: 5, zIndex: 1 }}>
        <LocationModal formType='edit' />
      </div>
      {imageSrc != null ? (
        <>
          <WxImageDetails>
            <Typography textOverflow='ellipsis' mr={1} noWrap>
              {wx?.name}
            </Typography>
            {lastUpdatedMins !== undefined && (
              <Typography variant='caption' overflow='visible' noWrap>
                {'Image updated ' + formatTimeStringFromMins(lastUpdatedMins)}
              </Typography>
            )}
          </WxImageDetails>
          <WxImage
            draggable='false'
            src={imageSrc}
            alt={'Weather Station Image'}
            onError={e => {
              setImageSrc(null);
            }}
          />
          {!isMobile && imageSrc && (
            <TrackedExpandImageButton
              sx={{ position: 'absolute', bottom: 5, right: 5 }}
              onClick={() => setExpandModalOpen(true)}>
              <Expand color={theme.palette.primary.main} />
            </TrackedExpandImageButton>
          )}
          {!isMobile && imageSrc && (
            <WxImageModal
              src={imageSrc}
              modalState={{ open: expandModalOpen, handleClose: () => setExpandModalOpen(false), handleOpen: () => {} }}
            />
          )}
        </>
      ) : (
        <WxComponent style={{ height: '100%' }} showStatus={true} />
      )}
    </WxImageContainer>
  );
}
