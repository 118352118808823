import { memo } from 'react';
import { PolicyType, usePolicyTypesQuery } from '../../Services/API';
import { Select, SelectOption, PWMenuHeaderItem, PWMenuItem } from '@perry-weather/component-library';
import { useTheme } from '@mui/material';

interface PolicyTypeSelectProps {
  value?: number;
  disabled?: boolean;
  placeholder?: string;
  onSelect: (policyType: PolicyType) => void;
}

export const PolicyTypeSelect = memo(function PolicyTypeSelect(props: PolicyTypeSelectProps) {
  const { value, onSelect, disabled, placeholder } = props;
  const { data: policyTypes } = usePolicyTypesQuery();
  const theme = useTheme();

  const sortedOptions = policyTypes ? [...policyTypes].sort((a, b) => a.categoryId - b.categoryId) : [];

  const groupedPolicyTypes = Object.groupBy(sortedOptions, ({ categoryName }) => categoryName);

  const getOptions = (policies: Partial<Record<string, PolicyType[]>>) => {
    const headers = Object.entries(policies).map(([key, value]) => {
      return { value: key, text: key, children: value?.map(({ id, displayName }) => ({ value: id, text: displayName })).sort(
        (a, b) => {
          const textA = a.text;
          const textB = b.text;
          return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
     })
    }});
    return headers
  };
  const renderHeaderItem = (option: SelectOption) => {
    return (
      <PWMenuHeaderItem
        key={option.value}
        style={{
          textTransform: 'uppercase',
          fontWeight: 'bold',
          color: theme.palette.text.primary,
        }}>
        {option.text}
      </PWMenuHeaderItem>
    );
  }

  const renderItem = (option: SelectOption) => {
    return (
      <PWMenuItem
        key={option.value}
        value={option.value}
        style={{
          marginLeft: '20px',
        }}>
        {option.text}
      </PWMenuItem>
    );
  }

  return (
    <Select
      placeholder={placeholder}
      disabled={disabled}
      onChange={(value, name) => {
        if (policyTypes) onSelect(policyTypes.find(x => x.id === parseInt(value)) || policyTypes[0]);
      }}
      value={value}
      options={getOptions(groupedPolicyTypes)}
      renderHeaderItem={renderHeaderItem}
      renderMenuItem={renderItem}
    />
  );
});
