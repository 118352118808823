import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import PauseIcon from '@mui/icons-material/Pause';
import BaseControl from '../Controls/BaseControl';
import { RadarState } from './Radar';
import { withClickTracking } from '../../../Tracked/withMixpanelTracking';
import { TrackableEvents } from '../../../Tracked/events';

interface PlayButtonProps {
  radarState: RadarState;
  handleRadarStateChange: (value: RadarState) => void;
}

const TrackedControl = withClickTracking(BaseControl)(TrackableEvents.ButtonClicked, {
  button_location: 'Dashboard',
  button_feature: 'Radar',
  button_label: 'Play Button',
  button_type: 'button',
});

export function PlayButton(props: PlayButtonProps) {
  const { radarState, handleRadarStateChange } = props;

  const playButtonHandleStateChange = () => {
    if (radarState === RadarState.PLAY) {
      handleRadarStateChange(RadarState.STATIC);
    } else {
      handleRadarStateChange(RadarState.PLAY);
    }
  };

  return (
    <div>
      <TrackedControl onClick={playButtonHandleStateChange} label='Play Button'>
        {radarState !== RadarState.PLAY ? <PlayArrowIcon /> : <PauseIcon />}
      </TrackedControl>
    </div>
  );
}

export default PlayButton;
