import BaseControl from './BaseControl';
import { Straighten } from '@mui/icons-material';
import { useAppDispatch, useAppSelector } from '../../../../app/hooks';
import { getActionType, setActionType } from '../../../../features/map/mapSlice';
import { withClickTracking } from '../../../Tracked/withMixpanelTracking';
import { TrackableEvents } from '../../../Tracked/events';

interface MeasureControlProps {}

const TrackedControl = withClickTracking(BaseControl)(TrackableEvents.ButtonClicked, {
  button_location: 'Dashboard',
  button_feature: 'Radar',
  button_label: 'Measure Button',
  button_type: 'button',
});

export function MeasureControl(props: MeasureControlProps) {
  const actionType = useAppSelector(getActionType);
  const measureActive = actionType === 'measure';
  const dispatch = useAppDispatch();

  const onSetMeasure = () => {
    if (measureActive) dispatch(setActionType(undefined));
    else dispatch(setActionType('measure'));
  };

  return (
    <div style={{ marginBottom: 10 }}>
      <TrackedControl selected={measureActive} label='Measure' onClick={() => onSetMeasure()}>
        <Straighten />
      </TrackedControl>
    </div>
  );
}
