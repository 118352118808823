/* eslint-disable react-hooks/exhaustive-deps */
import { useTheme, Typography, Zoom, Box } from '@mui/material';
import { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../../../../app/hooks';
import { PolicyTypeName } from '../../../../Constants/Constants';
import {
  addSevereLocation,
  getSelectedLocation,
  removeSevereLocation,
  selectLocation,
} from '../../../../features/dash/dashSlice';
import { useLightningStatusDelay } from '../../../../hooks/useLightningStatusDelay';
import {
  useObservationsForLocationQuery,
  Location,
  useCachedPolicyAlertsForLocationQuery,
  useNwsAlertsQuery,
} from '../../../../Services/API';
import { WxImage } from '../../../Base';
import React from 'react';
import { withClickTracking } from '../../../Tracked/withMixpanelTracking';
import { TrackableEvents } from '../../../Tracked/events';

interface LocationCardProps {
  location?: Location;
  onSelect?: (e: Location) => void;
  itemId: string;
  style?: React.CSSProperties;
  show?: boolean;
}

const TrackedBox = withClickTracking(Box)(TrackableEvents.LocationSelected, {
  button_location: 'Dashboard',
  button_label: 'Location Card',
  button_type: 'card',
});

export function LocationCard(props: LocationCardProps) {
  const { location, onSelect, style, show = true } = props;
  const theme = useTheme();

  const { data: nwsAlerts } = useNwsAlertsQuery(location ? location.id || '' : '', {
    skip: location === undefined,
  });
  const { data: observation } = useObservationsForLocationQuery(location ? location.id || '' : '', {
    skip: location === undefined,
  });
  const { data: policyViolations } = useCachedPolicyAlertsForLocationQuery(location?.id || '', {
    skip: location?.id === undefined,
  });

  const dispatch = useAppDispatch();
  useLightningStatusDelay(location?.id, 10000);

  useEffect(() => {
    if (location && location.id && nwsAlerts) {
      // NWS Bulletin
      if (nwsAlerts.length > 0)
        dispatch(
          addSevereLocation({
            locationId: location.id,
            threatType: { shortName: 'NWS', longName: [...nwsAlerts.map(x => x.Event)] },
          })
        );
      else dispatch(removeSevereLocation({ locationId: location.id, threatType: { shortName: 'NWS', longName: [] } }));
    }
  }, [nwsAlerts]);

  useEffect(() => {
    if (policyViolations && location && location.id !== undefined) {
      const alertSuffix = ' Policy Warning';
      const policyTypes: PolicyTypeName[] = [
        'WBGT',
        'WIND',
        'GUST',
        'AQI',
        'CHILL',
        'HEAT',
        'PRECIP',
        'AQI-PM2.5',
        'AQI-PM10',
        'AQI-O3',
        'AQI-MAX'
      ];

      policyTypes.forEach(policyType => {
        let exists = policyViolations.find(x => x.policyName === policyType);
        if (exists)
          dispatch(
            addSevereLocation({
              locationId: location.id!,
              threatType: { shortName: policyType, longName: [exists.policyDisplayName + alertSuffix] },
            })
          );
        else
          dispatch(
            removeSevereLocation({ locationId: location.id!, threatType: { shortName: policyType, longName: [] } })
          );
      });
    }
  }, [policyViolations]);

  //Not efficient at all. should prob call once from parent <- this is fine, it's cached
  const selectedLocation = useAppSelector(getSelectedLocation);

  const weatherStationInfo = observation?.weatherStation;

  const LocationCardComponent = () => {
    if (location) {
      return (
        <TrackedBox
          mpExtraData={{
            new_location_id: location.id,
            new_location_name: location.label,
            old_location_id: selectedLocation?.id,
            old_location_name: selectedLocation?.label,
          }}>
          <WxImage
            locationId={location.id}
            style={{ width: 240, maxHeight: 240, minHeight: 225, cursor: 'pointer' }}
            weatherStationId={weatherStationInfo && weatherStationInfo != null ? weatherStationInfo.id : undefined}
            selectable={true}
            showStatus={true}
          />
          <Typography
            textAlign='center'
            variant='body1'
            color={theme.palette.text.secondary}
            style={{ overflow: 'hidden' }}
            textOverflow={'ellipsis'}
            width={240}
            whiteSpace={'nowrap'}>
            {location.label}
          </Typography>
        </TrackedBox>
      );
    }
  };

  if (location !== undefined)
    return (
      <Zoom appear={false} in={show} style={{ ...style, outline: 0 }}>
        <div
          onClick={e => {
            if (onSelect) onSelect(location);
          }}
          style={{ display: 'flex', flexDirection: 'column', marginLeft: 10, marginRight: 10 }}>
          {LocationCardComponent()}
        </div>
      </Zoom>
    );
  else return <></>;
}
