// ** Config ** //
import { AuthProvider } from 'oidc-react';

// ** Styles ** //
import './App.css';
import { Theme } from '@mui/material/styles';

// ** Redux ** //
import { store } from './app/store';
import { Provider } from 'react-redux';

import { PWUserManager } from './config/oidcconfig';

// ** Main Component ** //
import Content from './Components/Shared/Content';
import DateAdapter from '@mui/lab/AdapterMoment';
import { LocalizationProvider } from '@mui/lab';
import { useEffect } from 'react';
import { PWThemeProvider } from '@perry-weather/component-library';
import { ErrorBoundary } from 'react-error-boundary';
import ErrorPage from './Pages/Error';

declare module '@mui/styles/defaultTheme' {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface DefaultTheme extends Theme {}
}

declare global {
  // Allows you to use hasOwnProperty as a type-guard to narrow down on type unions.
  // Specifically useful for awaiting rtk query mutation hooks, which return a union
  // of the data and error types.
  interface Object {
    hasOwnProperty<K extends PropertyKey>(key: K): this is Record<K, unknown>;
  }
  interface Window {
    gtag: (...args: any[]) => void;
  }
}


function App() {

  useEffect(() => {
    var script = document.createElement('script');
    script.src = 'https://status.perryweather.com/en/8d8a82d3/widget/script.js';
    document.body.appendChild(script);
  }, []);

  return (
    <ErrorBoundary fallback={ErrorPage()}>
      <Provider store={store}>
        <AuthProvider autoSignIn={false} userManager={PWUserManager}>
          <PWThemeProvider>
            <LocalizationProvider dateAdapter={DateAdapter}>
              <div className='App'>
                <Content />
              </div>
            </LocalizationProvider>
          </PWThemeProvider>
        </AuthProvider>
      </Provider>
    </ErrorBoundary>

  );
}

export default App;
