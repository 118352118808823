import { skipToken } from '@reduxjs/toolkit/dist/query';
import { useCustomerQuery, useUserQuery } from '../../../Services/API';
import { Bread } from '@perry-weather/component-library';
import { Container } from '@mui/material';

const useStyles = () => ({
  container: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: '8px',
  },
});
export default function CustomerBanner() {
  const { data: user } = useUserQuery();
  const { data: customer } = useCustomerQuery(user?.customerId ?? skipToken);
  const styles = useStyles();

  if (customer?.banner) {
    return (
      <Container maxWidth='xl' sx={styles.container}>
        <Bread header={customer.banner} variant='info'></Bread>
      </Container>
    );
  }

  return null;
}
