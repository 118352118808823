import { Box, Grid, Typography } from '@mui/material';
import Link from '../../Base/Link';
import { Skeleton } from '@mui/material';
import { Card } from '@perry-weather/component-library';
import { PollutantData, useGetAirQualityForLocationQuery } from '../../../Services/API';
import { useAppSelector } from '../../../app/hooks';
import { getSelectedLocation } from '../../../features/dash/dashSlice';
import { useState } from 'react';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import AirQualityDetailsModal from './AirQualityDetails';
import AirQualityGauge from './AqiGauge';
import { ErrorBoundary } from 'react-error-boundary';
import { withClickTracking } from '../../Tracked/withMixpanelTracking';
import { TrackableEvents } from '../../Tracked/events';

const TrackedLink = withClickTracking(Link)(TrackableEvents.ButtonClicked, {
  button_location: 'Dashboard',
  button_feature: 'Air Quality Index',
  button_label: 'Air Quality Details',
  button_type: 'link',
});

export function AirQualityCard() {
  const selectedLocation = useAppSelector(getSelectedLocation);

  const [detailsModalOpen, setDetailsModalOpen] = useState(false);
  const {
    data: airQualityData,
    isFetching: isAirQualityFetching,
    isLoading: isAirQualityLoading,
  } = useGetAirQualityForLocationQuery(selectedLocation?.id ?? skipToken);

  const [isSevere, setIsSevere] = useState(false); //Will need to hook this up once we have policies in place

  const showGauge = (pollutants: (PollutantData | undefined)[]) => {
    const data = pollutants.find(p => p?.isMainPollutant) ?? pollutants[0];

    if (!data) return null;

    return (
      <Grid
        item
        sx={{
          flexBasis: '100%',
        }}>
        <Typography fontSize={16}>{data?.pollutantName}</Typography>
        <AirQualityGauge width={200} pollutantData={data} />
      </Grid>
    );
  };

  return (
    <Card
      header='Air Quality'
      scary={isSevere}
      action={
        <Box
          display='flex'
          justifyContent='center'
          sx={{
            display: airQualityData && airQualityData.length > 0 ? 'flex' : 'none',
            textDecoration: 'none',
            alignItems: 'center',
            cursor: 'pointer',
          }}>
          <TrackedLink
            onClick={() => setDetailsModalOpen(true)}
            mpExtraData={{ location_id: selectedLocation?.id, location_name: selectedLocation?.locationName }}>
            <Typography>View Details</Typography>
          </TrackedLink>
        </Box>
      }>
      <ErrorBoundary fallback={<Typography>Your AQI data is taking a breather. Hang tight and we’ll be back soon!</Typography>}>
        {detailsModalOpen && (
          <AirQualityDetailsModal
            open={detailsModalOpen}
            handleClose={() => setDetailsModalOpen(false)}
            locationId={selectedLocation?.id as string}
          />
        )}
        {isAirQualityFetching || isAirQualityLoading ? (
          <Box display='flex' justifyContent='space-evenly'>
            <Skeleton animation={false} variant='rectangular' width='100%' height={150} />
          </Box>
        ) : airQualityData && airQualityData.length > 0 ? (
          <Box display='flex' justifyContent='space-evenly' alignItems={'center'} flexGrow={1}>
            {airQualityData.length > 0 && showGauge(airQualityData)}
          </Box>
        ) : (
          <Typography>No data is available for this location</Typography>
        )}
      </ErrorBoundary>
    </Card>
  );
}
export default AirQualityCard;
