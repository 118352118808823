import { useAuth } from 'oidc-react';
import { PropsWithChildren, ReactElement } from 'react';
import { CustomerEntitlements, UserRoles } from '../../Constants/Constants';

type PermissionGateProps = {
  allowedRoles?: UserRoles[]; // Array of roles that are allowed to view the children
  allowedEntitlements?: CustomerEntitlements[]; // A user must have one of these entitlements to view the children
  fallback?: React.ReactNode; // What gets shown if a user doesn't have permission. Defaults to nothing
};

// Hook to check if a user has certain roles or entitlements, which can be used independently of the declarative PermissionGate component
export const usePermissionGate = (props: {
  allowedRoles?: UserRoles[];
  allowedEntitlements?: CustomerEntitlements[];
}): boolean => {
  const { userData: user } = useAuth();
  return (
    (props.allowedRoles?.includes(user?.profile?.Role) ?? true) &&
    (props.allowedEntitlements?.some(entitlement => user?.profile?.permissions?.includes(entitlement)) ?? true)
  );
};

const PermissionGate = ({
  children,
  allowedRoles,
  allowedEntitlements,
  fallback,
}: PropsWithChildren<PermissionGateProps>): ReactElement => {
  const hasPermission = usePermissionGate({ allowedRoles, allowedEntitlements });

  if (hasPermission) {
    return (children ?? <></>) as ReactElement;
  }
  return (fallback ?? <></>) as ReactElement;
};

export default PermissionGate;
