import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// ** Components ** //
import Routes from '../../Routes';
import Background from './Background';
import Sidebar from './Sidebar';
import { Toast } from '@perry-weather/component-library';
import { closeToast, selectToast } from '../../features/toast/toastSlice';
import { useAppSelector } from '../../app/hooks';
import { useDispatch } from 'react-redux';
import { Route, useLocation } from 'wouter';
import { OldWidget } from '../../Pages/OldWidget';
import { MixpanelContainer } from './MixpanelContainer';

export default function Content() {
  const theme = useTheme();
  const dispatch = useDispatch();
  const toast = useAppSelector(selectToast);
  const dismissToast = () => {
    dispatch(closeToast());
  };

  const [location] = useLocation();

  if (location.toLocaleLowerCase().includes('public/')) {
    return (
      <>
        {/* Backwards compatibility for "Widget" until route change is made */}
        <Route path='/public/:customerName/:sirenName/Widget' component={OldWidget} />
      </>
    );
  } else
    return (
      <Box>
        <MixpanelContainer />
        <nav>
          <Sidebar />
        </nav>
        <main>
          <Box
            sx={{
              scrollbarColor: `${theme.palette.grey[500]} transparent`,
              flexGrow: 1,
              [theme.breakpoints.up('sm')]: {
                paddingLeft: theme.spacing(14),
                paddingRight: theme.spacing(14),
                paddingTop: theme.spacing(10),
              },
              [theme.breakpoints.down('sm')]: {
                paddingTop: theme.spacing(14),
              },
            }}>
            <Routes />
            <Toast {...toast} onClose={dismissToast} onToastClose={dismissToast} />
          </Box>
        </main>
        <Background />
      </Box>
    );
}
