export enum TrackableEvents {
  // Session Events
  SessionStarted = 'session_started',
  SessionExited = 'session_exited',

  // Generic User Events
  NavigationClicked = 'navigation_clicked',
  PageViewed = 'page_viewed',
  PageExited = 'page_exited',
  ViewClicked = 'view_clicked',
  ButtonClicked = 'button_clicked',

  // More Specific User Events
  LocationSelected = 'location_selected',
  LocationDeselected = 'location_deselected',

  PolicyCreated = 'policy_created',
  PolicyEdited = 'policy_edited',
  PolicyDeleted = 'policy_deleted',

  LocationCreated = 'location_created',
  LocationEdited = 'location_edited',
  LocationDeleted = 'location_deleted',

  HardwareEdited = 'hardware_edited',
  HardwareDeleted = 'hardware_deleted',

  ConsultationCreated = 'consultation_created',

  UserEdited = 'user_edited',
  UserDeleted = 'user_deleted',

  MassNotifyCreated = 'mass_notify_created',
}
