import { AirQuality, HeatIndex, WindChill, Globe, LightningStrike, RainDropOutline, Wind } from '../Assets';
import { Checkmark } from '../Assets/Checkmark';
import { PolicyTypeName, ThreatTypes } from '../Constants/Constants';
import { useTheme } from '@mui/styles';
import { WarningAmberRounded } from '@mui/icons-material';
import { Policy } from '../Services/API';

export const GetThreatIcon = (type: ThreatTypes, color: string, width: number, height: number) => {
  switch (type) {
    case 'WBGT':
      return <Globe width={width} height={height} color={color} />;
    case 'WIND':
      return <Wind width={width} height={height} color={color} />;
    case 'GUST':
      return <Wind width={width} height={height} color={color} />;
    case 'AQI':
    case 'AQI-PM2.5':
    case 'AQI-PM10':
    case 'AQI-O3':
    case 'AQI-MAX':
      return <AirQuality width={width} height={height} color={color} />;
    case 'CHILL':
      return <WindChill width={width} height={height} color={color} />;
    case 'HEAT':
      return <HeatIndex width={width} height={height} color={color} />;
    case 'PRECIP':
      return <RainDropOutline width={width} height={height} color={color} />;
    case 'LR1':
      return <LightningStrike width={width} height={height} />;
    case 'NWS':
      return <WarningAmberRounded width={width} height={height} htmlColor={color} />;
    case 'NONE':
      return <Checkmark width={width} height={height} color={color} />;
    default:
      return <></>;
  }
};

export const GetPolicyText = (type: PolicyTypeName) => {
  switch (type) {
    case 'WBGT':
      return type;
    case 'WIND':
      return 'Wind Speed';
    case 'GUST':
      return 'Wind Gust';
    case 'CHILL':
      return 'Wind Chill';
    case 'HEAT':
      return 'Heat Index';
    case 'PRECIP':
      return 'Precip';
    case 'LR1':
      return 'Lightning';
    case 'AQI':
    case 'AQI-MAX':
      return 'Air Quality';
    case 'AQI-PM2.5':
      return 'Air Quality PM2.5';
    case 'AQI-PM10':
      return 'Air Quality PM10';
    case 'AQI-O3':
        return 'Air Quality Ozone';
    default:
      return '';
  }
};

export const GetThreatLevelText = (threatLevel: number) => {
  switch (threatLevel) {
    case 0:
      return 'Low Chance';
    case 1:
      return 'Moderate Chance';
    case 2:
      return 'High Chance';
  }
};

export const GetThreatColor = (threatLevel: number) => {
  const theme = useTheme();

  switch (threatLevel) {
    case 0:
      return theme.palette.success.main;
    case 1:
      return theme.palette.warning.main;
    case 2:
      return theme.palette.error.main;
  }
};

export const GetFirstPolicy = (policies: Policy[], policyName: string) => {
  var filteredPolicies = policies?.filter(value => {
    return value.policyTypeShortName === policyName;
  });

  if (filteredPolicies.length > 0) {
    var isDesc = filteredPolicies[0].isDesc;
    if (!isDesc) {
      var orderedPolicies = filteredPolicies?.sort((a, b) => a.threshold - b.threshold);
      return orderedPolicies && orderedPolicies.length > 0 ? orderedPolicies[0] : undefined;
    } else {
      var orderedPoliciesDesc = filteredPolicies?.sort((a, b) => b.threshold - a.threshold);
      return orderedPoliciesDesc && orderedPoliciesDesc.length > 0 ? orderedPoliciesDesc[0] : undefined;
    }
  } else return undefined;
};

export const isSevere = (value: number, policy: Policy) => {
  if ((!policy.isDesc && value >= policy.threshold) || (policy.isDesc && value <= policy.threshold)) return true;
  else return false;
};
