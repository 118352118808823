import { LinearInterpolator, _useMapControl as useMapControl } from 'react-map-gl';
import MapState from 'react-map-gl/src/utils/map-state';
import { withClickTracking } from '../../../Tracked/withMixpanelTracking';
import { TrackableEvents } from '../../../Tracked/events';

const noop = () => {};

type ZoomProps = {
  type: 'zoom-in' | 'zoom-out';
  onClick: () => void;
};

const RenderButton = ({ type, onClick }: ZoomProps) => (
  <button key={type} className={`mapboxgl-ctrl-icon mapboxgl-ctrl-${type}`} onClick={onClick}>
    <span className='mapboxgl-ctrl-icon' aria-hidden='true' />
  </button>
);

const TrackedRenderButton = withClickTracking(RenderButton)(TrackableEvents.ButtonClicked, {
  button_location: 'Dashboard',
  button_feature: 'Radar',
  button_type: 'button',
});

function updateViewport(context: any, props: any, opts: any) {
  const { viewport } = context;
  const mapState = new MapState(Object.assign({}, viewport, opts));
  const viewState = Object.assign({}, mapState.getViewportProps(), new LinearInterpolator());
  viewState.transitionDuration = 200;

  const onViewportChange = props.onViewportChange || context.onViewportChange || noop;
  const onViewStateChange = props.onViewStateChange || context.onViewStateChange || noop;

  // Call new style callback
  onViewStateChange({ viewState });

  // Call old style callback
  onViewportChange(viewState);
}

export function ZoomControl(props: any) {
  const { context, containerRef } = useMapControl(props);

  const onZoomIn = () => {
    updateViewport(context, props, { zoom: context.viewport!.zoom + 1 });
  };

  const onZoomOut = () => {
    updateViewport(context, props, { zoom: context.viewport!.zoom - 1 });
  };

  // marginBottom 10 is not ideal, but quick fix, changing justifyContent doesn't seem to be working due to absolute positioning in parent..
  return (
    <div style={{ width: '100%', display: 'flex', flexDirection: 'column' }}>
      <div style={{ marginBottom: 10 }} className='mapboxgl-ctrl mapboxgl-ctrl-group' ref={containerRef}>
        <TrackedRenderButton type='zoom-in' onClick={onZoomIn} mpExtraData={{ button_label: 'zoom-in' }} />
      </div>
      <div style={{ marginBottom: 10 }} className='mapboxgl-ctrl mapboxgl-ctrl-group' ref={containerRef}>
        <TrackedRenderButton type='zoom-out' onClick={onZoomOut} mpExtraData={{ button_label: 'zoom-out' }} />
      </div>
    </div>
  );
}

export default ZoomControl;
