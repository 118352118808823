import { Grid, Typography, useTheme, Theme } from '@mui/material';
import { useEffect, useState } from 'react';
import { Button, Select, SelectOption, Page } from '@perry-weather/component-library';
import { useAuth } from 'oidc-react';
import { useCustomerQuery, useLazyLiveStreamLogsForCustomerQuery, useUserQuery } from '../Services/API';
import { Redirect, useLocation } from 'wouter';
import moment from 'moment';
import Table from '../Components/Base/BaseTable';
import { GridColDef } from '@mui/x-data-grid';
import TotalUsageRadialBar from '../Components/LiveCamera/TotalUsageRadialBar';
import { withRenderTracking } from '../Components/Tracked/withMixpanelTracking';
import { TrackableEvents } from '../Components/Tracked/events';

const TrackedPage = withRenderTracking(Page)(TrackableEvents.PageViewed, { page_name: 'Live Camera Logs' });

export interface ChartData {
  cameraBarData: RadialBarData[];
  userBarData: RadialBarData[];
}

export interface RadialBarData {
  id: string;
  data: RadialBarDataPoint[];
}

export interface RadialBarDataPoint {
  x: string;
  y: number;
}

export const getDurationString = (durationSeconds: number | undefined) => {
  if (!durationSeconds) return '';
  const duration = moment.duration(durationSeconds, 'seconds');
  let durationString = '';
  if (duration.hours() > 0) durationString += `${duration.hours()}h `;
  if (duration.minutes() > 0) durationString += `${duration.minutes()}m `;
  if (duration.seconds() > 0) durationString += `${duration.seconds()}s`;

  return durationString;
};

export function LiveCameraLogs(props: any) {
  const { customerId } = props.params;
  const { userData: authUser } = useAuth();
  const [getLiveSteamLogsForCustomer, liveStreamLogsResult] = useLazyLiveStreamLogsForCustomerQuery();
  const { data: liveStreamLogs } = liveStreamLogsResult;
  const { data: customer } = useCustomerQuery(customerId);
  const theme = useTheme();
  const [location, setLocation] = useLocation();
  const [selectedMonth, setSelectedMonth] = useState<number>(0);
  const [chartData, setChartData] = useState<ChartData>();
  const [totalSecondsConsumed, setTotalSecondsConsumed] = useState<number>(0);

  useEffect(() => {
    getLiveSteamLogsForCustomer({ customerId: customerId, monthOffset: 0 });
  }, []);

  useEffect(() => {
    if (customer && liveStreamLogs) {
      const totalSeconds = liveStreamLogs.reduce((total, log) => total + log.duration, 0);
      setTotalSecondsConsumed(totalSeconds);

      let cameraMinutesMap = new Map<string, number>();
      let userMinutesMap = new Map<string, number>();
      liveStreamLogs.forEach(log => {
        if (cameraMinutesMap.has(log.cameraFeedName)) {
          const minutes = cameraMinutesMap.get(log.cameraFeedName);
          cameraMinutesMap.set(log.cameraFeedName, minutes! + log.duration);
        } else {
          cameraMinutesMap.set(log.cameraFeedName, log.duration);
        }

        if (userMinutesMap.has(log.userName)) {
          const minutes = userMinutesMap.get(log.userName);
          userMinutesMap.set(log.userName, minutes! + log.duration);
        } else {
          userMinutesMap.set(log.userName, log.duration);
        }
      });

      const cameraUsageData = Array.from(cameraMinutesMap).map(([cameraName, duration]) => {
        return { x: cameraName, y: Math.floor(duration / 60) } as RadialBarDataPoint;
      });

      const userUsageData = Array.from(userMinutesMap).map(([userName, duration]) => {
        return { x: userName, y: Math.floor(duration / 60) } as RadialBarDataPoint;
      });

      const cameraBarData = [
        {
          id: 'Cameras',
          data: cameraUsageData,
        },
      ];

      const userBarData = [
        {
          id: 'Users',
          data: userUsageData,
        },
      ];

      setChartData({ cameraBarData, userBarData });
    }
  }, [customer, liveStreamLogs]);

  //make select list options for past 6 months
  const getMonths = () => {
    const months = [];
    for (let i = 0; i < 6; i++) {
      const month = moment().subtract(i, 'months').format('MMMM YYYY');
      months.push(month);
    }
    return months;
  };

  const months = getMonths();

  const monthSelectOptions = months?.map((month, index) => {
    return { value: index, text: month } as SelectOption;
  });

  const handleMonthSelect = (month: string) => {
    var value = parseInt(month);
    setSelectedMonth(value);
    getLiveSteamLogsForCustomer({ customerId: customerId, monthOffset: value });
  };

  const getLocalDateString = (date: string) => {
    if (!date.endsWith('Z')) date += 'Z';
    return new Date(date).toLocaleString();
  };

  const hasLiveCameraAccess =
    authUser?.profile?.permissions?.includes('live_camera.access') &&
    (authUser?.profile?.Role === 'Admin' ||
      authUser?.profile?.Role === 'Super Admin' ||
      authUser?.profile?.Role === 'Assistant Admin');
  if (!hasLiveCameraAccess) return <Redirect to='/Unauthorized' />;

  const columns: GridColDef[] = [
    {
      field: 'id',
      headerName: 'ID',
      sortable: true,
      flex: 1,
      hide: true,
      renderCell: e => e.value,
    },
    {
      field: 'cameraFeedName',
      headerName: 'Camera',
      sortable: true,
      flex: 1,
      renderCell: e => e.value,
    },
    {
      field: 'userName',
      headerName: 'User',
      sortable: true,
      flex: 1,
      renderCell: e => e.value,
    },
    {
      field: 'start',
      headerName: 'Start Time',
      sortable: true,
      flex: 1,
      renderCell: e => getLocalDateString(e.value as string),
    },
    {
      field: 'duration',
      headerName: 'Duration',
      sortable: true,
      flex: 1,
      renderCell: e => getDurationString(e.value as number),
    },
  ];

  return (
    <TrackedPage
      title={
        <Typography variant='h3' color='textPrimary' noWrap>
          Live Camera - Logs
        </Typography>
      }
      pageAction={
        <Grid container alignItems={'flex-end'} justifyContent={'flex-end'}>
          <Grid item xs={6}>
            <Select
              options={monthSelectOptions}
              placeholder={'Select Month'}
              onChange={value => handleMonthSelect(value)}
              value={selectedMonth}
              // value={widgetParams.orgLocId}
            />
          </Grid>

          <Grid item xs={6}>
            <Button onClick={() => setLocation('/LiveCamera')}>Live Camera</Button>
          </Grid>
        </Grid>
      }>
      {liveStreamLogs && liveStreamLogs.length > 0 && customer && customer.liveStreamMinutes && (
        <Grid container flexDirection={'column'}>
          <Grid
            container
            flexDirection={'column'}
            justifyContent='flex-start'
            alignItems={'flex-start'}
            marginBottom={10}>
            <Grid item>
              <Typography variant='h5' color='textPrimary' noWrap>
                Allowed this month:
                <span style={{ color: theme.palette.info.main }}>
                  {' ' + Math.floor(customer.liveStreamMinutes / 60)} hours{' '}
                  {customer.liveStreamMinutes % 60 > 0 && (customer.liveStreamMinutes % 60) + ' minutes'}{' '}
                </span>
              </Typography>
            </Grid>
            <Grid item>
              <Typography variant='h5' color='textPrimary' noWrap>
                Consumed:
                <span
                  style={{
                    color:
                      totalSecondsConsumed / 60 > customer.liveStreamMinutes
                        ? theme.palette.error.main
                        : theme.palette.info.main,
                  }}>
                  {' ' + getDurationString(totalSecondsConsumed)}
                </span>
              </Typography>
            </Grid>
          </Grid>
          <Grid container flexDirection={'row'} flexWrap={'wrap'} justifyContent={'space-around'}>
            <Grid item>
              <Typography variant='h5' color='textPrimary' noWrap>
                Cameras
              </Typography>
              {chartData && chartData.cameraBarData && (
                <TotalUsageRadialBar data={chartData.cameraBarData} maxValue={customer.liveStreamMinutes} />
              )}
            </Grid>
            <Grid item>
              <Typography variant='h5' color='textPrimary' noWrap>
                Users
              </Typography>
              {chartData && chartData.userBarData && (
                <TotalUsageRadialBar data={chartData.userBarData} maxValue={customer.liveStreamMinutes} />
              )}
            </Grid>
          </Grid>
          <Table cols={columns} rows={liveStreamLogs} rowHeight={50} rowSpace={10} rowsPerPageOptions={[10, 25, 50]} />
        </Grid>
      )}
      {liveStreamLogs && liveStreamLogs.length === 0 && (
        <Typography variant='h5' marginTop={20} color='textPrimary' noWrap>
          No logs found for this month.
        </Typography>
      )}
    </TrackedPage>
  );
}
