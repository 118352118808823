import { Checkbox, CircularProgress, Grid, Menu, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { Button, SearchField, Page, Pagination } from '@perry-weather/component-library';
import WidgetsOutlinedIcon from '@mui/icons-material/WidgetsOutlined';
import { WidgetCard } from '../Components/Widget/WidgetCard';
import { Redirect, useLocation } from 'wouter';
import { useCustomerWidgetsQuery, Widget as WidgetInterface, WidgetSize } from '../Services/API/widgetApi';
import { useUserQuery } from '../Services/API';
import { getWidgetParams } from './WidgetBuilder';
import { useAuth } from 'oidc-react';
import { SuperAdminWidgetModal } from '../Components/Widget/SuperAdminWidgetModal';
import { ArrowDropDownOutlined } from '@mui/icons-material';
import { withClickTracking, withRenderTracking } from '../Components/Tracked/withMixpanelTracking';
import { TrackableEvents } from '../Components/Tracked/events';

const TrackedPage = withRenderTracking(Page)(TrackableEvents.PageViewed, { page_name: 'Widgets' });
const TrackedGrid = withRenderTracking(Grid)(TrackableEvents.PageViewed, { page_name: 'Widgets' });
const TrackedButton = withClickTracking(Button)(TrackableEvents.ButtonClicked, {
  button_location: 'Widgets',
  button_feature: 'Add Widget',
  button_type: 'button',
});

const WIDGETS_PER_PAGE = 6;

export function Widgets(props: any) {
  const { data: user } = useUserQuery();
  const { userData } = useAuth();
  const [customerId, setCustomerId] = useState<number>(0);
  const { data: widgets, isLoading } = useCustomerWidgetsQuery(customerId, { skip: user == undefined });
  const [location, setLocation] = useLocation();
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [filteredWidgets, setFilteredWidgets] = useState<WidgetInterface[]>(widgets || []);
  const [searchString, setSearchString] = useState<string>('');
  const [searchByAnchor, setSearchByAnchor] = useState<Element | null>(null);
  const [selectedSearchFields, setSelectedSearchFields] = useState<Array<string>>(['name', 'customerName']);

  const openSearchBy = Boolean(searchByAnchor);
  const searchFields = ['name', 'customerName'];

  let startIndex = (currentPage - 1) * WIDGETS_PER_PAGE;
  var endIndex =
    widgets && currentPage * WIDGETS_PER_PAGE < filteredWidgets.length
      ? currentPage * WIDGETS_PER_PAGE
      : filteredWidgets.length;
  const widgetsOnPage = filteredWidgets.slice(startIndex, endIndex);

  const hasWidgetAccess =
    userData?.profile?.permissions?.includes('widget.access') &&
    (userData?.profile?.Role === 'Admin' ||
      userData?.profile?.Role === 'Super Admin' ||
      userData?.profile?.Role === 'Assistant Admin');
  const hasWidgetAdminAccess = userData?.profile?.WIDGET_ADMIN === 'ALL' && userData?.profile?.Role === 'Super Admin';

  const handleSearchFieldsChange = (field: string) => {
    if (selectedSearchFields.includes(field)) {
      setSelectedSearchFields(selectedSearchFields.filter(selectedField => selectedField !== field));
    } else {
      setSelectedSearchFields([...selectedSearchFields, field]);
    }
  };

  const handleNavigate = (widgetId: string, customerId: number) => {
    setLocation(`/widgets/${customerId}/${widgetId}`);
  };

  const handleSearch = (searchTerm: string) => {
    setSearchString(searchTerm);
  };

  useEffect(() => {
    if (user && user.customerId) {
      setCustomerId(user.customerId);
    }
  }, [user]);

  useEffect(() => {
    if (widgets) {
      setFilteredWidgets(widgets);
    }
  }, [widgets]);

  useEffect(() => {
    let filtered;
    const searchable = (value: string): string => {
      if (!value) return '';

      ['.', ','].forEach(replaceVal => {
        value = value.replaceAll(replaceVal, '');
      });

      return value.toUpperCase();
    };

    if (!searchString) {
      filtered = widgets;
    } else if (widgets && searchString.length > 2) {
      filtered = widgets.filter((widget: any) => {
        return selectedSearchFields.some((searchKey: string) => {
          const searchValue = widget[searchKey] as string;

          return searchable(searchValue).includes(searchable(searchString));
        });
      });
    }

    setFilteredWidgets(filtered ?? []);
    setCurrentPage(1);
  }, [searchString, widgets, selectedSearchFields]);

  const NoWidgets = () => (
    <div
      style={{
        position: 'absolute',
        left: 0,
        top: 0,
        height: '100vh',
        width: '100%',
        display: 'flex',
        alignItems: 'center',
      }}>
      <TrackedGrid
        container
        margin={1}
        alignContent='center'
        alignItems='center'
        display='flex'
        flexDirection='column'
        justifyContent='center'>
        <Grid item>
          <WidgetsOutlinedIcon color='primary' style={{ width: 85, height: 85 }} />
        </Grid>

        <Grid item container flexDirection={'column'} justifyContent={'center'} alignItems={'center'} maxWidth={'90%'}>
          <Grid item>
            <Typography variant='h4'>Build your first widget – it’s quick and simple!</Typography>
          </Grid>
          <Grid item>
            <Typography variant='h6' maxWidth={'700px'} marginTop={'20px'}>
              Display your Perry Weather data on your website, TVs, mobile apps, or anywhere – including lightning
              timer, current conditions, custom messages, and more.
            </Typography>
          </Grid>
        </Grid>

        <Grid item maxWidth={510} marginTop={'20px'}>
          {hasWidgetAdminAccess ? (
            <SuperAdminWidgetModal button={<Button>Add Widget</Button>} />
          ) : (
            <TrackedButton
              type='primary'
              onClick={() => handleNavigate('builder', customerId)}
              mpExtraData={{ button_label: 'Add Widget' }}>
              Add Widget
            </TrackedButton>
          )}
        </Grid>
      </TrackedGrid>
    </div>
  );

  return (
    <>
      {hasWidgetAccess ? (
        <TrackedPage
          title={
            <Typography variant='h3' color='textPrimary' noWrap>
              Widget Builder
            </Typography>
          }
          pageAction={
            <Grid container flexDirection={'row'} justifyContent={'space-between'}>
              {widgets && widgets.length > 0 && (
                <Grid xs={8} item>
                  <SearchField
                    handleSearch={handleSearch}
                    disabled={isLoading}
                    additionalEndAdornment={
                      hasWidgetAccess && (
                        <Grid order={{ xs: 4, lg: 2 }} item sx={{ marginRight: '-14px' }}>
                          <TrackedButton
                            type='clear'
                            onClick={e => setSearchByAnchor(e.currentTarget)}
                            mpExtraData={{ button_label: 'Search By' }}>
                            <Typography>Search By</Typography>
                            <ArrowDropDownOutlined />
                          </TrackedButton>
                          <Menu open={openSearchBy} anchorEl={searchByAnchor} onClose={e => setSearchByAnchor(null)}>
                            {searchFields.map((field, ind) => (
                              <Grid container sx={{ padding: '0 8px' }} key={field}>
                                <Grid item>
                                  <Checkbox
                                    sx={ind === 0 ? { cursor: 'not-allowed' } : null}
                                    disableFocusRipple={ind === 0}
                                    disableRipple={ind === 0}
                                    disableTouchRipple={ind === 0}
                                    checked={ind === 0 || selectedSearchFields.includes(field)}
                                    value={field}
                                    onChange={(e: any) => {
                                      if (ind !== 0) handleSearchFieldsChange(e.target.value);
                                    }}
                                  />
                                </Grid>
                                <Grid item display='flex' flexDirection='column' justifyContent='center'>
                                  <Typography sx={{ padding: '0 8px' }}>
                                    {field.split('').reduce((acc: string, cur: string, ind: number) => {
                                      const charCode = cur.charCodeAt(0);

                                      if (ind === 0) {
                                        acc = cur.toUpperCase();
                                      } else if (charCode >= 65 && charCode <= 90) {
                                        acc += ' ' + cur;
                                      } else {
                                        acc += cur;
                                      }

                                      return acc;
                                    }, '')}
                                  </Typography>
                                </Grid>
                              </Grid>
                            ))}
                          </Menu>
                        </Grid>
                      )
                    }
                  />
                </Grid>
              )}
              <Grid xs={3} item>
                {hasWidgetAdminAccess && !isLoading ? (
                  <SuperAdminWidgetModal
                    button={<TrackedButton mpExtraData={{ button_label: 'Add Widget' }}>Add Widget</TrackedButton>}
                  />
                ) : (
                  widgets &&
                  widgets?.length > 0 &&
                  !isLoading && (
                    <Button type='primary' onClick={() => handleNavigate('builder', customerId)}>
                      Add Widget
                    </Button>
                  )
                )}
              </Grid>
            </Grid>
          }>
          {widgets && widgets.length === 0 ? (
            <NoWidgets />
          ) : isLoading ? (
            <CircularProgress />
          ) : (
            <Grid container spacing={2} justifyContent={'center'}>
              <Grid container item alignItems={'stretch'} spacing={2}>
                {widgetsOnPage &&
                  widgetsOnPage.map(widget => {
                    const params = getWidgetParams(widget);
                    params.width = 800;

                    return (
                      <Grid key={widget.id} item xs={12} md={4}>
                        <WidgetCard
                          widgetId={widget.id}
                          size={WidgetSize.xl}
                          navigate={handleNavigate}
                          widgetParams={params}
                        />
                      </Grid>
                    );
                  })}
              </Grid>
              <Grid item justifyContent={'center'}>
                <Pagination
                  count={Math.ceil(filteredWidgets.length / WIDGETS_PER_PAGE)}
                  page={currentPage}
                  onChange={(event, page) => {
                    setCurrentPage(page);
                  }}
                />
              </Grid>
            </Grid>
          )}
        </TrackedPage>
      ) : (
        <Redirect to='/Unauthorized' />
      )}
    </>
  );
}
