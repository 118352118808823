import { Box } from '@mui/material';
import { CurrentCondtionComponent } from '../CurrentConditions/CurrentConditionComponent';
import { Policy, useSitePoliciesQuery } from '../../Services/API';
import { GetFirstPolicy, isSevere } from '../../features/ThreatHelper';
import { useEffect } from 'react';

interface CurrentConditionsProps {
    conditions: CurrentConditions;
    isApprox: boolean;
    setIsSevere?: React.Dispatch<React.SetStateAction<boolean>>;
}

interface CurrentConditions {
    temp?: number;
    precip?: number;
    feelsLike?: number;
    wbgt?: number;
    windSpeed?: number;
    windDirection: number;
    calibratedConditions?: CalibratedData;
    windDirectionText?: string;
}
interface CalibratedData {
    precip?: number;
    precipUpdatedTime?: string;
}

export function CurrentConditionsContainer(props: CurrentConditionsProps) {
    const { conditions, isApprox, setIsSevere } = props;
    const { data: policies } = useSitePoliciesQuery();
    let shouldSetToScary = false;

    const RenderComponents = () => {
        var components: JSX.Element[] = [];

        if (conditions.temp || conditions.temp === 0) {
            components.push(<CurrentCondtionComponent value={Math.round(conditions.temp)} type="Temp" key="temp" />);
        }

        if (conditions.precip || conditions.precip === 0) {
            let precipToDisplay = Math.round(conditions.precip * 100) / 100;
            let shouldDisplayCorrectPrecip = false;
            let precipPolicy = policies && GetFirstPolicy(policies, 'PRECIP');
            if (precipPolicy && isSevere(conditions.precip, precipPolicy)) shouldSetToScary = true;
            if (
                conditions.calibratedConditions &&
                conditions.calibratedConditions?.precip !== undefined &&
                conditions.calibratedConditions?.precipUpdatedTime !== undefined
            ) {
                shouldDisplayCorrectPrecip = true;
                precipToDisplay = Math.round(conditions.calibratedConditions.precip * 100) / 100;
            }

            components.push(
                <CurrentCondtionComponent key="precip" value={precipToDisplay} type="Precip" policy={precipPolicy} stDev={0.5} isZeroMin />
            );
        }

        if ((conditions.temp || conditions.temp === 0) && (conditions.feelsLike || conditions.feelsLike === 0)) {
            let feelsPolicy: Policy | undefined;

            if (conditions.temp >= 65) {
                feelsPolicy = policies && GetFirstPolicy(policies, 'HEAT');
            } else if (conditions.temp < 65) {
                feelsPolicy = policies && GetFirstPolicy(policies, 'CHILL');
            }

            if (feelsPolicy && isSevere(conditions.feelsLike, feelsPolicy)) shouldSetToScary = true;

            components.push(
                <CurrentCondtionComponent key="feels" value={Math.round(conditions.feelsLike)} type="Feels" policy={feelsPolicy} stDev={1} />
            );
        }

        if (conditions.wbgt || conditions.wbgt === 0) {
            let wbgtPolicy = policies && GetFirstPolicy(policies, 'WBGT');

            if (wbgtPolicy && isSevere(conditions.wbgt, wbgtPolicy)) shouldSetToScary = true;

            components.push(
                <CurrentCondtionComponent
                    key="wbgt"
                    value={Math.round(conditions.wbgt * 10) / 10}
                    type="WBGT"
                    isApprox={isApprox}
                    policy={wbgtPolicy}
                    stDev={1}
                />
            );
        }

        if (conditions.windSpeed || conditions.windSpeed === 0) {
            let windPolicy = policies && GetFirstPolicy(policies, 'WIND');

            if (windPolicy && isSevere(conditions.windSpeed, windPolicy)) shouldSetToScary = true;

            components.push(
                <CurrentCondtionComponent
                    key="wind"
                    value={Math.round(conditions.windSpeed)}
                    direction={conditions.windDirection}
                    type="Wind"
                    policy={windPolicy}
                    stDev={1}
                    isZeroMin
                    windDirectionText={conditions.windDirectionText}
                />
            );
        }
        return components;
    };

    useEffect(() => {
        if (setIsSevere) setIsSevere(shouldSetToScary);
    }, [setIsSevere, shouldSetToScary, props]);

    return (
        <Box display="flex" justifyContent="space-around" flex="1 1 auto" flexWrap={'wrap'} gap={1} alignItems="center">
            {RenderComponents()}
        </Box>
    );
}
export default CurrentConditionsContainer;
