import { api } from '.';

// Random note to self: a shared type library would be so useful for this

export enum FilterTypeCategories {
    Roles = 'Roles',
    CompanyType = 'Company Type'
}

export interface ProactiveForecastArea {
    id: string;
    areaName: string;
    polygon: string;
    polygonColor: string;
}

export interface ProactiveForecastMessage {
    id: string;
    proactiveForecastId: string;
    headline: string;
    message: string;
    createdAt: string;
}

export interface ProactiveForecastType {
    id: number;
    name: string;
    color: string;
}

export interface ProactiveForecastFilterType {
    id: number;
    name: string;
    category: FilterTypeCategories;
    subcategory: string;
    description?: string;
}

export interface ProactiveForecast {
    id: string;
    expiresAt: string;
    proactiveForecastAreaId: string;
    messages: ProactiveForecastMessage[];
    filters: ProactiveForecastFilterType[];
    forecastType: ProactiveForecastType;
}

const proactiveForecastApi = api.injectEndpoints({
    endpoints: builder => ({
        proactiveForecastsForLocation: builder.query<ProactiveForecast[], string>({
            query: id => ({ url: `ProactiveForecast/Location/${id}` }),
            transformResponse: (response: { data: ProactiveForecast[] }) => response.data
        }),
        proactiveForecastMessage: builder.query<ProactiveForecastMessage, string>({
            query: id => ({ url: `ProactiveForecast/Message/${id}` }),
            transformResponse: (response: { data: ProactiveForecastMessage }) => response.data
        })
    })
});

export const { useProactiveForecastsForLocationQuery, useProactiveForecastMessageQuery } = proactiveForecastApi;
