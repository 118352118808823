import { useTheme } from '@mui/material';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { openToast } from '../../features/toast/toastSlice';
import { PWError, useDeleteNotiUserMutation, useDeleteUserMutation, User } from '../../Services/API';
import { DeletionDialog } from '../Base/DeletionDialog';
import mixpanel from 'mixpanel-browser';
import { TrackableEvents } from '../Tracked/events';

interface UserDeleteModalProps {
  user: User;
}

export function UserDeleteModal(props: UserDeleteModalProps) {
  const { user } = props;
  const theme = useTheme();
  const dispatch = useDispatch();

  const [deleteUser, { data: deleteResponse, isError: deleteError, isSuccess: deleteSuccess, error }] =
    useDeleteUserMutation();
  const [
    deleteNotiUser,
    { data: deleteNotiResponse, isError: isDeleteNotiError, isSuccess: deleteNotiSuccess, error: deleteNotiError },
  ] = useDeleteNotiUserMutation();

  const handleDelete = () => {
    if (user.roleId === '-1') deleteNotiUser(user.id);
    else deleteUser(user.id);
  };

  useEffect(() => {
    if (deleteSuccess) {
      mixpanel.track(TrackableEvents.UserDeleted, { user_id: user.id, user_name: user.userName });
      dispatch(openToast({ variant: 'success', header: deleteResponse || '' }));
    } else if (deleteError) {
      dispatch(openToast({ variant: 'error', header: (error as PWError)?.data.responseException.message }));
    }
  }, [deleteSuccess, deleteError, deleteResponse, error, user]);

  useEffect(() => {
    if (deleteNotiSuccess) {
      mixpanel.track(TrackableEvents.UserDeleted, { user_id: user.id, user_name: user.userName });
      dispatch(openToast({ variant: 'success', header: deleteNotiResponse || '' }));
    } else if (isDeleteNotiError) {
      dispatch(openToast({ variant: 'error', header: (deleteNotiError as PWError)?.data.responseException.message }));
    }
  }, [deleteNotiSuccess, isDeleteNotiError, deleteNotiError, deleteNotiResponse, user]);

  return (
    <DeletionDialog
      onRemove={handleDelete}
      item='User'
      identifier={user.fullName}
      iconColor={theme.palette.error.main}
    />
  );
}
