import { ContentCopyOutlined } from '@mui/icons-material';
import { Grid, IconButton, Typography, useTheme } from '@mui/material';
import { useEffect, useState } from 'react';
import { useAppDispatch } from '../../app/hooks';
import { openToast } from '../../features/toast/toastSlice';
import { useSendWidgetInstructionsMutation, useUserQuery, WidgetInstructionsDto } from '../../Services/API';
import { Button, Select, TextField, Modal } from '@perry-weather/component-library';
import { withClickTracking } from '../Tracked/withMixpanelTracking';
import { TrackableEvents } from '../Tracked/events';

const TrackedButton = withClickTracking(Button)(TrackableEvents.ButtonClicked, {
  button_location: 'Widgets',
  button_feature: 'Share Widget',
  button_type: 'button',
});

const TrackedIconButton = withClickTracking(IconButton)(TrackableEvents.ButtonClicked, {
  button_location: 'Widgets',
  button_feature: 'Share Widget',
  button_type: 'icon',
});

export interface ShareWidgetModalProps {
  open: boolean;
  handleOpen: () => void;
  handleClose: () => void;
  widgetId: string;
}

export const ShareWidgetModal = (props: ShareWidgetModalProps) => {
  const { open, handleOpen, handleClose, widgetId } = props;
  const dispatch = useAppDispatch();
  const [embedWidth, setEmbedWidth] = useState('100%');
  const [recipient, setRecipient] = useState('');
  const theme = useTheme();
  const { data: user } = useUserQuery();
  const [sendEmail, { isSuccess: sendEmailSuccess, isError: sendEmailError }] = useSendWidgetInstructionsMutation();

  const handleEmbedWidthChange = (width: string) => {
    setEmbedWidth(width);
  };

  const handleRecipientChange = (recipient: string) => {
    console.log(recipient);
    setRecipient(recipient);
  };

  function isValidEmail(email: string): boolean {
    return /\S+@\S+\.\S+/.test(email);
  }

  const handleSendEmailInstructions = () => {
    if (!isValidEmail(recipient)) {
      dispatch(openToast({ variant: 'error', header: 'Please enter a valid email address.' }));
      return;
    }
    if (user) {
      const instructionsDto: WidgetInstructionsDto = {
        recipient: recipient,
        widgetId: widgetId,
        sender: user.fullName,
      };
      sendEmail(instructionsDto);
    }
  };

  useEffect(() => {
    if (sendEmailSuccess) {
      dispatch(openToast({ variant: 'success', header: 'Email sent!' }));
    }
    if (sendEmailError) {
      dispatch(openToast({ variant: 'error', header: 'There was an error sending the email.' }));
    }
  }, [sendEmailSuccess, sendEmailError]);

  const embedTemplate = `<div style="width: ${embedWidth}; border-radius: 30px; position:relative" id="pwWidget:${widgetId}">
        <script src="https://widget.perryweather.com/js/PwIframe.min.js"> </script>
        <script>
            PwIframe.createPerryWeatherWidget('${widgetId}');
        </script>
    </div> `;

  const widgetSrc = `https://widget.perryweather.com/?id=${widgetId}`;

  return (
    <Modal modalState={{ open: open, handleOpen: handleOpen, handleClose: handleClose }}>
      <Grid container flexDirection={'column'}>
        <Grid item>
          <Typography variant='h6'>Share Widget</Typography>
        </Grid>
        <hr style={{ margin: '12px 0' }} />
        <Grid item>
          <Grid container flexDirection={'row'} justifyContent={'space-between'}>
            <Grid item xs={6}>
              <Typography variant='h6'>Copy embed code</Typography>
            </Grid>
            <Grid item xs={4}>
              <Select
                options={[
                  { text: 'Responsive', value: '100%' },
                  { text: '300px', value: '300px' },
                  { text: '400px', value: '400px' },
                  { text: '600px', value: '600px' },
                  { text: '800px', value: '800px' },
                  { text: '1000px', value: '1000px' },
                ]}
                placeholder={'Select a location'}
                onChange={(value: string) => handleEmbedWidthChange(value)}
                value={embedWidth}
              />
            </Grid>
          </Grid>
          <Typography variant='body2' color='textSecondary'>
            Embeddable code to place on your website.
          </Typography>
          <TextField
            value={embedTemplate}
            endAdornment={
              <TrackedIconButton
                onClick={() => navigator.clipboard.writeText(embedTemplate)}
                mpExtraData={{ wiget_id: widgetId, button_label: 'Copy Embed Code to Clipboard' }}>
                <ContentCopyOutlined />
              </TrackedIconButton>
            }
          />
        </Grid>
        <hr style={{ margin: '12px 0' }} />
        <Grid item container flexDirection={'row'} flexWrap='nowrap' justifyContent={'space-between'}>
          <Grid item>
            <Typography textAlign={'left'} variant='h6'>
              Send via email
            </Typography>
            <Typography textAlign={'left'} variant='body2' color={theme.palette.text.secondary}>
              Send a guide on implementing the Perry Weather Widget to someone in your organization.
            </Typography>
          </Grid>
        </Grid>

        <Grid
          item
          container
          flexDirection={'row'}
          flexWrap='nowrap'
          justifyContent={'space-between'}
          alignItems={'center'}
          sx={{ marginTop: '10px' }}>
          <Grid item xs={10}>
            <TextField value={recipient} onChange={e => handleRecipientChange(e.target.value)} inputMode='email' />
          </Grid>
          <Grid item>
            <TrackedButton
              onClick={() => handleSendEmailInstructions()}
              mpExtraData={{ button_label: 'Send (via email)', widget_id: widgetId }}>
              Send
            </TrackedButton>
          </Grid>
        </Grid>
        <hr style={{ margin: '12px 0' }} />
        <Grid item sx={{ marginTop: '10px' }}>
          <Typography variant='h6'>Shareable URL</Typography>
          <Typography variant='body2' color='textSecondary'>
            Share this url with anyone who needs your widget.
          </Typography>
          <TextField
            value={widgetSrc}
            endAdornment={
              <TrackedIconButton
                onClick={() => navigator.clipboard.writeText(widgetSrc)}
                mpExtraData={{ widget_id: widgetId, button_label: 'Copy Sharable URL to Clipboard' }}>
                <ContentCopyOutlined />
              </TrackedIconButton>
            }
          />
        </Grid>
        <TrackedButton
          onClick={() => handleClose()}
          style={{ marginTop: '10px', alignSelf: 'flex-end' }}
          mpExtraData={{ button_label: 'Close', widget_id: widgetId }}>
          Close
        </TrackedButton>
      </Grid>
    </Modal>
  );
};
