import { Grid, Typography, useTheme } from '@mui/material';
import { PollutantData } from '../../../Services/API';
import ProgressCircle from '../../Animated/ProgressCircle';

type AirQualityGaugeProps = {
  pollutantData?: PollutantData;
  width?: number;
};

const AQI_RANGE_MAX = 300;
const AQI_RANGE_MIN = 0;
const SEGMENT_BOUNDS = [0, 5, 25, 50, 75, 100, 125, 150, 175, 200, 225, 250, 275, 300, Infinity];

export default function AirQualityGauge({ pollutantData, width = 100 }: AirQualityGaugeProps) {
  const theme = useTheme();

  const aqiIndex = pollutantData?.aqiData?.nowCastIndex;

  const fixedSegmentValue = (value: number | undefined) => {
    let fill = 0;

    if (typeof value === 'number' && !isNaN(value)) {
        let segmentIndex = 0;
        for (let i = 0; i < SEGMENT_BOUNDS.length - 1; i++) {
            if (value >= SEGMENT_BOUNDS[i] && value <= SEGMENT_BOUNDS[i + 1]) {
                segmentIndex = i;
                break;
            }
        }

        // If the AQI value is greater than 300, set the segmentIndex to the last segment, otherwise increment it by 1 so that we are rounding up to the next segment
        if (value > 300) segmentIndex = SEGMENT_BOUNDS.length - 1;
        else segmentIndex++;

        fill = (segmentIndex / (SEGMENT_BOUNDS.length - 1)) * AQI_RANGE_MAX;
    }

    return fill;
  };

  return (
    <Grid container flexDirection={'column'} justifyContent={'center'} alignItems={'center'}>
      <ProgressCircle
        width={width}
        value={fixedSegmentValue(aqiIndex?.value)}
        max={AQI_RANGE_MAX}
        min={AQI_RANGE_MIN}
        color={aqiIndex?.color}
      />
      <Typography marginTop={-6} variant='h3'>
        {aqiIndex?.value ?? '--'}
      </Typography>
      <Typography marginTop={2} fontSize={18} textAlign='center' color={theme.palette.text.primary}>
        {aqiIndex?.shortDescription ?? 'N/A'}
      </Typography>
    </Grid>
  );
}
