import { Box, CardHeader, Grid, Typography } from '@mui/material';
import { Button, Card, Modal } from '@perry-weather/component-library';
import AirQualityGauge from './AqiGauge';
import { PollutantData, useGetAirQualityForLocationQuery } from '../../../Services/API';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import { AqiType } from '../../../Constants/AqiConstants';
import moment from 'moment';
import { withClickTracking } from '../../Tracked/withMixpanelTracking';
import { TrackableEvents } from '../../Tracked/events';

type AirQualityDetailsModalProps = {
  locationId: string;
  open: boolean;
  handleClose: () => void;
  handleOpen?: () => void;
};

const Row = ({ label, value }: { label: string; value: string | number }) => (
  <Box justifyContent={'space-between'} flexDirection={'row'} display={'flex'}>
    <Typography>{label}:</Typography>
    <Typography align='right'>{value}</Typography>
  </Box>
);

interface PollutantDataProps {
  pollutant?: PollutantData;
}

const PollutantDetails = ({ pollutant }: PollutantDataProps) => {
  const sourceName = pollutant?.sourceName === 'PerryWeather' ? 'Perry Weather' : pollutant?.sourceName;

  return (
    <Box>
      <Row label='NowCast' value={pollutant?.aqiData?.nowCastIndex?.value ?? '-'} />
      <Row label='1-Hr AQI' value={pollutant?.aqiData.oneHourIndex?.value ?? '-'} />
      {pollutant?.pollutantName === 'PM2.5' || pollutant?.pollutantName === 'PM10' ? (
        <Row label='24-Hr AQI' value={pollutant?.aqiData.twentyFourHourIndex?.value ?? '-'} />
      ) : (
        <Row label='8-Hr AQI' value={pollutant?.aqiData.eightHourIndex?.value ?? '-'} />
      )}
      <Row label='Source' value={sourceName ?? '-'} />
      <Row label='Distance' value={`${pollutant?.distanceInMiles?.toFixed(2) ?? '-'} mi`} />
    </Box>
  );
};

const getLastUpdatedTime = (pollutantData: PollutantData) => {
  return pollutantData?.utcTimeStamp ? moment.utc(pollutantData.utcTimeStamp).fromNow() : 'N/A';
};

const TrackedCloseButton = withClickTracking(Button)(TrackableEvents.ButtonClicked, {
  button_location: 'Dashboard',
  button_feature: 'Air Quality Index',
  button_label: 'Close Air Quality Details',
  button_type: 'button',
});

export default function AirQualityDetailsModal({
  locationId,
  open,
  handleClose,
  handleOpen = () => {},
}: AirQualityDetailsModalProps) {
  const { data: airQualityData } = useGetAirQualityForLocationQuery(locationId ?? skipToken);

  const pm25Data = airQualityData?.find(x => x.pollutantName === AqiType.PM25);
  const pm10Data = airQualityData?.find(x => x.pollutantName === AqiType.PM10);
  const o3Data = airQualityData?.find(x => x.pollutantName === AqiType.O3);

  const pm25Order = pm25Data?.isMainPollutant ? 1 : 2;
  const pm10Order = pm10Data?.isMainPollutant ? 1 : 2;
  const o3Order = o3Data?.isMainPollutant ? 1 : 2;

  const primaryPollutantHeader = 'Primary pollutant impacting air quality in your area';

  return (
    <Modal modalState={{ open, handleClose, handleOpen }}>
      <Box display={'flex'} flexDirection={'column'}>
        <Typography component='h5' fontSize={24} marginBottom={2} order={0}>
          Air Quality Details
        </Typography>

        <Box marginBottom={4} order={pm25Order}>
          <Card sx={{ card: { border: pm25Order === 1 && '1px solid #59e1ff' } }} fullContent>
            {pm25Data?.isMainPollutant && (
              <CardHeader
                subheaderTypographyProps={{ color: 'black' }}
                subheader={primaryPollutantHeader}
                sx={{ margin: 0, padding: 1, textAlign: 'center', background: '#59e1ff' }}
              />
            )}

            <Box paddingX={3} paddingY={2}>
              <Grid container justifyContent={'space-between'}>
                <Box flexBasis={'45%'}>
                  <Typography variant='h6' fontWeight={'bold'}>
                    PM2.5
                  </Typography>
                </Box>
                <Box alignSelf='right'>
                  <Typography variant='caption'>{pm25Data && 'Updated ' + getLastUpdatedTime(pm25Data)}</Typography>
                </Box>
              </Grid>

              <Grid container justifyContent={'space-between'}>
                <Box flexBasis={'45%'}>
                  <PollutantDetails pollutant={pm25Data} />
                </Box>
                <Box justifyContent={'center'} alignContent={'center'} flexBasis={'45%'}>
                  <AirQualityGauge pollutantData={pm25Data} width={175} />
                </Box>
              </Grid>

              <Typography paddingTop={2}>
                <strong>PM2.5</strong> (Fine Particulate Matter): Tiny particles (≤2.5μm) that penetrate deep into the
                lungs, impacting heart and lung health.
              </Typography>
            </Box>
          </Card>
        </Box>

        <Box marginBottom={4} order={pm10Order}>
          <Card sx={{ card: { border: pm10Order === 1 && '1px solid #59e1ff' } }} fullContent>
            {pm10Data?.isMainPollutant && (
              <CardHeader
                subheaderTypographyProps={{ color: 'black' }}
                subheader={primaryPollutantHeader}
                sx={{ margin: 0, padding: 1, textAlign: 'center', background: '#59e1ff' }}
              />
            )}

            <Box paddingX={3} paddingY={2}>
              <Grid container justifyContent={'space-between'}>
                <Box flexBasis={'45%'}>
                  <Typography variant='h6' fontWeight={'bold'}>
                    PM10
                  </Typography>
                </Box>
                <Box alignSelf='right'>
                  <Typography variant='caption'>{pm10Data && 'Updated ' + getLastUpdatedTime(pm10Data)}</Typography>
                </Box>
              </Grid>

              <Grid container justifyContent={'space-between'}>
                <Box flexBasis={'45%'}>
                  <PollutantDetails pollutant={pm10Data} />
                </Box>
                <Box justifyContent={'center'} alignContent={'center'} flexBasis={'45%'}>
                  <AirQualityGauge pollutantData={pm10Data} width={175} />
                </Box>
              </Grid>

              <Typography paddingTop={2}>
                <strong>PM10</strong> (Coarse Particulate Matter): Larger particles (≤10μm) like dust and pollen that
                can irritate the respiratory system.
              </Typography>
            </Box>
          </Card>
        </Box>

        <Box marginBottom={4} order={o3Order}>
          <Card sx={{ card: { border: o3Order === 1 && '1px solid #59e1ff' } }} fullContent>
            {o3Data?.isMainPollutant && (
              <CardHeader
                subheaderTypographyProps={{ color: 'black' }}
                subheader={primaryPollutantHeader}
                sx={{ margin: 0, padding: 1, textAlign: 'center', background: '#59e1ff' }}
              />
            )}

            <Box paddingX={3} paddingY={2}>
              <Grid container justifyContent={'space-between'}>
                <Box flexBasis={'45%'}>
                  <Typography variant='h6' fontWeight={'bold'}>
                    Ozone
                  </Typography>
                </Box>
                <Box alignSelf='right'>
                  <Typography variant='caption'>{o3Data && 'Updated ' + getLastUpdatedTime(o3Data)}</Typography>
                </Box>
              </Grid>

              <Grid container justifyContent={'space-between'}>
                <Box flexBasis={'45%'}>
                  <PollutantDetails pollutant={o3Data} />
                </Box>
                <Box justifyContent={'center'} alignContent={'center'} flexBasis={'45%'}>
                  <AirQualityGauge pollutantData={o3Data} width={175} />
                </Box>
              </Grid>

              <Typography paddingTop={2}>
                <strong>Ozone</strong> (O<sub>3</sub>): A pollutant formed by sunlight reacting with emissions, causing
                breathing issues, especially for sensitive groups.
              </Typography>
            </Box>
          </Card>
        </Box>
      </Box>

      <Box display='flex' justifyContent='flex-end' width='100%'>
        <TrackedCloseButton type='clear' onClick={handleClose} mpExtraData={{ location_id: locationId }}>
          <Typography variant='body1'>Close</Typography>
        </TrackedCloseButton>
      </Box>
    </Modal>
  );
}
