import { useEffect } from 'react';
import mixpanel from 'mixpanel-browser';
import { getToken } from '../../Services/Auth/authSlice';
import { useAppSelector } from '../../app/hooks';
import { useCustomerQuery, useUserQuery } from '../../Services/API';
import { TrackableEvents } from '../Tracked/events';
import { skipToken } from '@reduxjs/toolkit/dist/query';

const { REACT_APP_MIXPANEL_KEY } = process.env;
const RECORD_SESSIONS_PERCENT = 0.01; // 1% of sessions will be recorded
const MS_PER_MINUTE = 60000;

if (REACT_APP_MIXPANEL_KEY) {
  mixpanel.init(REACT_APP_MIXPANEL_KEY, {
    debug: process.env.REACT_APP_ENVIRONMENT !== 'production',
    record_idle_timeout_ms: 1 * MS_PER_MINUTE,
    record_max_ms: 15 * MS_PER_MINUTE,
    record_mask_text_selector: '.mp-masked',
    // record_sessions_percent: 1, // let mixpanel record a small sample of sessions
    // @ts-ignore This is a valid key
    autocapture: {
      pageview: false,
      click: false,
      input: false,
      scroll: true,
      submit: false,
      capture_text_content: false,
    },
  });
  // Disable events until the user is identified
  mixpanel.disable();
}

export const MixpanelContainer = () => {
  // Once a session token is available, identify the user in Mixpanel
  const token = useAppSelector(getToken);
  const { data: user, isSuccess } = useUserQuery(undefined, { skip: !REACT_APP_MIXPANEL_KEY || !token });
  const { data: customer, isSuccess: isCustomerSuccess } = useCustomerQuery(user?.customerId ?? skipToken);

  const readyToInit = isSuccess && isCustomerSuccess && user && customer;
  const readyToInitMixpanel = readyToInit && REACT_APP_MIXPANEL_KEY;

  useEffect(() => {
    // Not strictly mixpanel related, but analytics related so it goes here. Sets a token denoting the customer status
    // when it's active, so that www.perryweather.com can track whether a visitor is already a paying customer or not
    if (readyToInit) {
      if (customer.status === 'ACTIVE') {
        const host = window.location.host;
        const domain = host.slice(host.indexOf('.'));
        document.cookie = `pw_customer_status=${customer.status}; Domain=${domain}; SameSite= None; Secure; Max-Age=31536000`;
      }
    }

    if (readyToInitMixpanel) {
      mixpanel._flags.disable_all_events = false;
      mixpanel.identify(user.id);
      mixpanel.people.set({
        $email: user.email,
        $phone: user.phoneNumber,
        username: user.userName,
        user_created: user.created,
        customer_id: customer.id,
        customer_name: customer.name,
        customer_created: customer.created,
        customer_type: customer.companyType,
        email: user.email,
        first_name: user.firstName,
        last_name: user.lastName,
        role: user.roleName,
      });
      mixpanel.track(TrackableEvents.SessionStarted);

      // This will time the session if the user explicitly logs out
      mixpanel.time_event(TrackableEvents.SessionExited);

      const shouldRecordSession = !customer.name.includes('Perry Weather') && Math.random() < RECORD_SESSIONS_PERCENT;
      if (shouldRecordSession) {
        mixpanel.start_session_recording();
      }

      return () => {
        if (shouldRecordSession) {
          mixpanel.stop_session_recording();
        }
      };
    }
  }, [readyToInit]);

  return null;
};
