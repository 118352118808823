import { Box, Grid, Typography, useTheme } from '@mui/material';
import { useEffect, useRef, useState } from 'react';
import { Button, Card, Page, Modal } from '@perry-weather/component-library';
import { WidgetCard } from '../Components/Widget/WidgetCard';
import { Policy, PWError, useCustomerOrgLocationsQuery, usePoliciesForCustomerQuery } from '../Services/API';
import {
  PaletteType,
  useUpdateWidgetMutation,
  useWidgetQuery,
  Widget,
  WidgetType,
  cType,
  ForecastType,
  WidgetSize,
  useCreateWidgetMutation,
  useDeleteWidgetMutation,
} from '../Services/API/widgetApi';
import { openToast } from '../features/toast/toastSlice';
import { useAppDispatch } from '../app/hooks';
import { WidgetBuilderOptions } from '../Components/Widget/WidgetBuilderOptions';
import { Redirect, useLocation } from 'wouter';
import { WarningAmberOutlined } from '@mui/icons-material';
import { useAuth } from 'oidc-react';
import { withClickTracking, withRenderTracking } from '../Components/Tracked/withMixpanelTracking';
import { TrackableEvents } from '../Components/Tracked/events';

const TrackedPage = withRenderTracking(Page)(TrackableEvents.PageViewed, { page_name: 'Widget Builder' });
const TrackedButton = withClickTracking(Button)(TrackableEvents.ButtonClicked, {
  button_location: 'Widget Builder',
  button_feature: 'Widget Builder',
  button_type: 'button',
});

export interface WidgetParams {
  id?: string;
  theme: PaletteType;
  type: WidgetType;
  orgLocId: string | undefined;
  widgetConditions: number[];
  primaryCondition: cType;
  hourlyWidgetForecast: cType | -1;
  dailyWidgetForecast: cType | -1;
  weatherStationId: string;
  primaryThreshold?: number;
  size?: WidgetSize;
  customerId: number;
  customerName?: string;
  name: string;
  width?: number;
  policyGroupId?: number;
  policies?: Policy[];
  lightningRadius?: number;
  headerMessage?: string;
  headerMessageExpiration?: Date;
  creatorId?: string;
}

export interface Condition {
  conditionType: number;
  threshold?: number;
}

export interface BuilderError {
  name: boolean;
  lightningRadius: boolean;
}

const defaultWidgetParams: WidgetParams = {
  id: 'builder',
  theme: PaletteType.DARK,
  type: WidgetType.DEF,
  orgLocId: undefined,
  widgetConditions: [],
  primaryCondition: cType.TEMP,
  hourlyWidgetForecast: -1,
  dailyWidgetForecast: -1,
  weatherStationId: 'builder',
  size: WidgetSize.xs,
  customerId: 0,
  name: '',
  width: 800,
  policyGroupId: -1,
};

export const getWidgetParams = (widget: Widget) => {
  const widgetConditions = widget.widgetConditions.map(c => c.conditionType);
  const hourForecast = widget.widgetForecasts.find(c => c.forecastType === ForecastType.HOURLY)?.conditionType;
  const hourForecastType = hourForecast != undefined && hourForecast in cType ? hourForecast : -1;

  const dailyWidgetForecast = widget.widgetForecasts.find(c => c.forecastType === ForecastType.DAILY)?.conditionType;
  const dailyForecastType = dailyWidgetForecast != undefined && dailyWidgetForecast in cType ? dailyWidgetForecast : -1;

  const params: WidgetParams = {
    id: widget.id,
    theme: widget.theme,
    type: widget.type,
    orgLocId: widget.orgLocId,
    widgetConditions: widgetConditions,
    primaryCondition: widget.primaryCondition,
    hourlyWidgetForecast: hourForecastType,
    dailyWidgetForecast: dailyForecastType,
    weatherStationId: widget.weatherStationId,
    primaryThreshold: widget.primaryThreshold,
    customerId: widget.customerId,
    customerName: widget.customerName,
    name: widget.name,
    policyGroupId: widget.policyGroupId,
    headerMessage: widget.headerMessage,
    headerMessageExpiration: widget.headerMessageExpiration
      ? new Date(widget.headerMessageExpiration + 'Z')
      : undefined,
    width: 800,
    creatorId: widget.creatorId,
  };

  return params;
};

interface WidgetBuilderProps {
  widgetId: string;
  customerId: number;
}

export function WidgetBuilder(props: any) {
  const { widgetId, customerId } = props.params;
  const [widgetParams, setWidgetParams] = useState<WidgetParams>({
    ...defaultWidgetParams,
    customerId: customerId,
  } as WidgetParams);
  const { data: widget } = useWidgetQuery(widgetId);
  const { data: orgLocations } = useCustomerOrgLocationsQuery(customerId);
  const { data: customerPolicies } = usePoliciesForCustomerQuery(customerId);
  const [builderError, setBuilderError] = useState<BuilderError>({ name: false, lightningRadius: false });
  const [
    updateWidget,
    {
      data: updateWidgetResponse,
      isLoading: updateWidgetIsLoading,
      isSuccess: updateWidgetSuccess,
      isError: updateWidgetError,
      error: updateWidgetErrorValue,
    },
  ] = useUpdateWidgetMutation();
  const [
    createWidget,
    {
      data: createWidgetResponse,
      isLoading: createWidgetIsLoading,
      isSuccess: createWidgetSuccess,
      isError: createWidgetError,
      error: createWidgetErrorValue,
    },
  ] = useCreateWidgetMutation();
  const [
    deleteWidget,
    {
      data: deleteWidgetResponse,
      isLoading: deleteWidgetIsLoading,
      isSuccess: deleteWidgetSuccess,
      isError: deleteWidgetError,
      error: deleteWidgetErrorValue,
    },
  ] = useDeleteWidgetMutation();
  const dispatch = useAppDispatch();
  const [location, setLocation] = useLocation();
  const [modalOpen, setModalOpen] = useState(false);
  const theme = useTheme();
  const { userData } = useAuth();

  const handleWidgetParamUpdate = (
    key: string,
    value: string | number[] | string[] | number | Policy[] | Date | undefined
  ) => {
    const newParams = { ...widgetParams, [key]: value } as WidgetParams;
    if (key == 'width' && typeof value == 'number') {
      newParams.width = value >= 300 ? value : 300;
    }
    setWidgetParams(newParams);
  };

  const handleBackNavigate = () => {
    setLocation('/widgets');
  };

  useEffect(() => {
    if (widget) {
      const params = getWidgetParams(widget);
      if (params && params.orgLocId != undefined) {
        setWidgetParams(params);
      }
    }
  }, [widget]);

  useEffect(() => {
    if (updateWidgetSuccess) {
      dispatch(openToast({ variant: 'success', header: 'Widget updated successfully.' }));
    }
    if (updateWidgetError) {
      let errorMessage = 'Unable to update widget. ';
      if (updateWidgetErrorValue) {
        const pwError = updateWidgetErrorValue as PWError;
        if (pwError.data) {
          errorMessage += pwError.data;
        }
      }
      dispatch(openToast({ variant: 'error', header: errorMessage }));
    }
  }, [updateWidgetSuccess, updateWidgetError]);

  useEffect(() => {
    if (createWidgetSuccess) {
      setLocation('/widgets');
    }
    if (createWidgetError) {
      let errorMessage = 'Unable to create widget. ';
      if (createWidgetErrorValue) {
        const error = createWidgetErrorValue as PWError;
        if (error.data) {
          errorMessage += error.data;
        }
      }
      dispatch(openToast({ variant: 'error', header: errorMessage }));
    }
  }, [createWidgetSuccess, createWidgetError]);

  useEffect(() => {
    if (deleteWidgetSuccess) {
      setLocation('/widgets');
    }
    if (deleteWidgetError) {
      console.log('closing modal');
      handleModalClose();
      let errorMessage = 'Unable to delete widget. ';
      if (deleteWidgetErrorValue) {
        const error = deleteWidgetErrorValue as PWError;
        if (error.data) {
          errorMessage += error.data;
        }
      }
      dispatch(openToast({ variant: 'error', header: errorMessage }));
    }
  }, [deleteWidgetSuccess, deleteWidgetError]);

  const handleWidgetSave = () => {
    let selectedPolicies: Policy[] | undefined = [];
    if (widgetParams.policyGroupId != undefined && customerPolicies) {
      var id = widgetParams.policyGroupId;
      selectedPolicies = customerPolicies.filter(p => p.sites.includes(id));
    }

    widgetParams.policies = selectedPolicies;
    const widget = Widget.fromWidgetParams(widgetParams);

    const error = {
      name: widget.name == undefined || widget.name == '',
      lightningRadius: widget.lightningRadius == undefined,
    };

    if (error.name) {
      setBuilderError(error);
      dispatch(openToast({ variant: 'error', header: 'Please enter a name for your widget.' }));
      return;
    }
    if (error.lightningRadius) {
      setBuilderError(error);
      dispatch(
        openToast({
          variant: 'error',
          header: 'Lightning radius is required. Please select a group with a lightning radius policy.',
        })
      );
      return;
    }

    if (widget.id === 'builder') {
      widget.id = undefined;
      widget.creatorId = userData?.profile?.sub;
      createWidget(widget);
    } else {
      updateWidget(widget);
    }
  };

  const handleModalOpen = () => {
    setModalOpen(true);
  };
  const handleModalClose = () => {
    setModalOpen(false);
  };
  const handleWidgetDelete = () => {
    if (widgetParams.id) deleteWidget(widgetParams.id);
  };

  const hasWidgetAccess =
    userData?.profile?.permissions?.includes('widget.access') &&
    (userData?.profile?.Role === 'Admin' ||
      userData?.profile?.Role === 'Super Admin' ||
      userData?.profile?.Role === 'Assistant Admin');
  const hasWidgetAdminAccess = userData?.profile?.WIDGET_ADMIN === 'ALL' && userData?.profile?.Role === 'Super Admin';

  if (!hasWidgetAccess) {
    return <Redirect to='/Unauthorized' />;
  }
  if (!hasWidgetAdminAccess && userData && userData.profile.customer_id != customerId) {
    return <Redirect to='/Unauthorized' />;
  }

  return (
    <>
      <TrackedPage
        title={
          <Typography variant='h3' color='textPrimary' noWrap>
            Widget Builder
          </Typography>
        }
        pageAction={
          <Grid container flexDirection={'row'} justifyContent={'flex-end'} spacing={2}>
            <Grid item>
              <TrackedButton
                type='clearBlue'
                onClick={() => handleBackNavigate()}
                mpExtraData={{ button_label: 'Back to Widgets' }}>
                Back to Widgets
              </TrackedButton>
            </Grid>
            {widgetId !== 'builder' && (
              <Grid item>
                <TrackedButton
                  type='delete'
                  onClick={handleModalOpen}
                  mpExtraData={{ button_label: 'Delete Widget', widget_id: widgetId, widget_name: widgetParams.name }}>
                  Delete Widget
                </TrackedButton>
              </Grid>
            )}
          </Grid>
        }>
        <Grid container spacing={2}>
          <Grid xs={12} md={6} item container>
            <WidgetBuilderOptions
              widgetParams={widgetParams}
              handleWidgetParamUpdate={handleWidgetParamUpdate}
              orgLocations={orgLocations}
              builderError={builderError}
            />
          </Grid>

          <Grid xs={12} md={6} item container alignSelf={'flex-start'} position={'sticky'} top={'10%'}>
            <Card header={'Preview your widget'}>
              <WidgetCard
                widgetId={widgetId}
                size={widgetParams?.size ?? WidgetSize.xs}
                navigate={() => {}}
                isBuilderPreview
                widgetParams={widgetParams}
              />
              <TrackedButton
                mpExtraData={{ button_label: 'Save Widget', widget_id: widgetId, widget_name: widgetParams.name }}
                onClick={() => handleWidgetSave()}
                style={{ marginTop: '10px' }}
                type='primary'
                disabled={updateWidgetIsLoading || widgetParams.orgLocId == undefined || createWidgetIsLoading}>
                Save Widget
              </TrackedButton>
            </Card>
          </Grid>
        </Grid>
      </TrackedPage>

      <Modal modalState={{ open: modalOpen, handleOpen: handleModalOpen, handleClose: handleModalClose }}>
        <Grid container item style={{ marginBottom: 8 }}>
          <Grid item md={2} style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
            <Box display='flex' justifyContent='center' width='100%'>
              <Box component='span' display='flex' flexDirection='column' justifyContent='center'>
                <Box display='flex' justifyContent='center' width='100%'>
                  <WarningAmberOutlined htmlColor={theme.palette.warning.main} />
                </Box>
              </Box>
            </Box>
          </Grid>
          <Grid item md={10} xs={12}>
            <Typography variant='h5'>Delete {widgetParams.name}?</Typography>
            <Typography textAlign={'left'} variant='body2' color={theme.palette.text.secondary}>
              Widget will be permanently deleted! Only delete if you are certain you no longer need this widget.
            </Typography>
          </Grid>
          <Grid container flexDirection={'row'} justifyContent={'flex-end'} spacing={2} sx={{ marginTop: '10px' }}>
            <Grid item>
              <Button type='clear' onClick={() => handleModalClose()}>
                Cancel
              </Button>
            </Grid>
            <Grid item>
              <Button type='delete' onClick={() => handleWidgetDelete()}>
                Delete Widget
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Modal>
    </>
  );
}
