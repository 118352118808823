/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useMemo, useState } from 'react';
import { SubCardContainer, Label, subtleLinkClass } from '../Components/Base';
import { Box, Grid, RadioGroup, Typography } from '@mui/material';
import {
    AlertTime,
    PWError,
    useAlertTimesQuery,
    useLocationsQuery,
    useNotificationSettingsQuery,
    useSitePoliciesQuery,
    useUpdateAlertTimesMutation,
    useUpdateForecastLocationMutation,
    useUpdateNotificationSettingsMutation
} from '../Services/API';
import { Card, Select, Checkbox, Radio, SelectOption, Switch, Bread, BreadVariant, Page } from '@perry-weather/component-library';
import { RainDropOutline, BellRingOutline, CellphoneOutline } from '../Assets';
import { DaysOfWeek } from '../features/Time/TimeConstant';
import { useTheme } from '@mui/styles';
import { useDispatch } from 'react-redux';
import { openToast } from '../features/toast/toastSlice';
import { getPolicyAlertsIcon, timezones } from '../utils/utils';
import { InfoOutlined, Lock, WarningAmberRounded } from '@mui/icons-material';
import { useAuth } from 'oidc-react';
import { withRenderTracking } from '../Components/Tracked/withMixpanelTracking';
import { TrackableEvents } from '../Components/Tracked/events';
import { CloudAlert } from '../Assets/NotificationIcons/cloud-alert';
const TrackedPage = withRenderTracking(Page)(TrackableEvents.PageViewed, { page_name: 'Notification Settings' });

type AlertTimeUpdateTypes = 'endtime' | 'starttime' | 'enabled' | 'allday';

function GenerateOptions() {
    let options: SelectOption[] = [];

    options.push({ text: 'ALL DAY', value: 'allday', bold: true });

    for (let i = 0; 24 > i; i++) {
        let twelveHourValue = ((i + 11) % 12) + 1;
        let suffix = i > 11 ? 'PM' : 'AM';
        let prefix = i < 10 ? '0' + i : i;
        options.push({ text: twelveHourValue + ':00 ' + suffix, value: prefix + ':00:00' });
        options.push({ text: twelveHourValue + ':15 ' + suffix, value: prefix + ':15:00' });
        options.push({ text: twelveHourValue + ':30 ' + suffix, value: prefix + ':30:00' });
        options.push({ text: twelveHourValue + ':45 ' + suffix, value: prefix + ':45:00' });
    }

    return options;
}

export function NotificationSettings() {
    const theme = useTheme();
    const linkClass = subtleLinkClass(theme);

    const dispatch = useDispatch();
    const { data: notiSet } = useNotificationSettingsQuery();
    const { data: alertTimes } = useAlertTimesQuery();
    const { data: policyTriggers } = useSitePoliciesQuery();

    const [
        updateNotificationSettings,
        { isSuccess: notiUpdateSuccess, isError: notiUpdateError, data: settingsUpdateResponse, error: settingsUpdateError }
    ] = useUpdateNotificationSettingsMutation();
    const [
        updateAlertTimes,
        { isSuccess: alertUpdateSuccess, data: alertUpdateResponse, isError: alertUpdateError, error: alertUpdateErrorResponse }
    ] = useUpdateAlertTimesMutation();
    const [
        updateForcastLocation,
        {
            isSuccess: isForecastLocationUpdateSuccess,
            data: updateForecastLocationData,
            isError: isForecastLocationUpdateError,
            error: updateForecastLocationError
        }
    ] = useUpdateForecastLocationMutation();
    const { data: userLocations } = useLocationsQuery();

    const [notificationSettings, setNotificationSettings] = useState(notiSet || null);
    const [notificationAlertTimes, setAlertTimes] = useState(alertTimes || null);
    const [timer, setTimer] = useState<NodeJS.Timeout | undefined>(undefined);

    const timeSelectionOptions = GenerateOptions();

    useMemo(() => {
        if (notiSet) {
            setNotificationSettings(notiSet);
        }
        if (alertTimes) {
            setAlertTimes(alertTimes);
        }
    }, [notiSet, alertTimes]);

    const handleToastOpen = (variant: BreadVariant, header?: string) => {
        if (timer) clearTimeout(timer);

        setTimer(
            setTimeout(() => {
                dispatch(openToast({ variant: variant, header: header || '' }));
            }, 1000)
        );
    };

    useEffect(() => {
        if (notiUpdateSuccess) {
            handleToastOpen('success', settingsUpdateResponse);
        } else if (notiUpdateError) handleToastOpen('error', (settingsUpdateError as PWError)?.data.responseException.message);
    }, [notiUpdateSuccess, notiUpdateError, settingsUpdateResponse, settingsUpdateError]);

    useEffect(() => {
        if (isForecastLocationUpdateSuccess) {
            handleToastOpen('success', updateForecastLocationData);
        } else if (isForecastLocationUpdateError) handleToastOpen('error', (updateForecastLocationError as PWError)?.data.responseException.message);
    }, [isForecastLocationUpdateError, updateForecastLocationError, updateForecastLocationData, isForecastLocationUpdateSuccess]);

    useEffect(() => {
        if (alertUpdateSuccess) {
            handleToastOpen('success', alertUpdateResponse);
        } else if (alertUpdateError) handleToastOpen('error', (alertUpdateErrorResponse as PWError)?.data.responseException.message);
    }, [alertUpdateSuccess, alertUpdateError, alertUpdateResponse, alertUpdateErrorResponse]);

    if (notificationSettings !== null && notificationAlertTimes !== null) {
        const setDailyForecast = (checkedState: boolean) => {
            updateNotificationSettings({ ...notificationSettings, optOutForecast: !checkedState });
            setNotificationSettings({ ...notificationSettings, optOutForecast: !checkedState });
        };

        const setDeclinesNotifications = (checkedState: boolean) => {
            updateNotificationSettings({ ...notificationSettings, declinesNotifications: !checkedState });
            setNotificationSettings({ ...notificationSettings, declinesNotifications: !checkedState });
        };

        // const setLightiningNotification = (checkedState: boolean) => {
        //   updateNotificationSettings({ ...notificationSettings, lightningNotification: checkedState })
        //   setNotificationSettings({ ...notificationSettings, lightningNotification: checkedState });
        // }

        const setPrecipitationNotification = (checkedState: boolean) => {
            updateNotificationSettings({ ...notificationSettings, precipitationNotification: checkedState });
            setNotificationSettings({ ...notificationSettings, precipitationNotification: checkedState });
        };

        const setSevereWeatherNotification = (checkedState: boolean) => {
            updateNotificationSettings({ ...notificationSettings, severeWeatherNotification: checkedState });
            setNotificationSettings({ ...notificationSettings, severeWeatherNotification: checkedState });
        };

        const setProactiveForecastNotification = (checkedState: boolean) => {
            updateNotificationSettings({ ...notificationSettings, proactiveForecastNotification: checkedState });
            setNotificationSettings({ ...notificationSettings, proactiveForecastNotification: checkedState });
        };

        const setNotificationType = (type: number) => {
            updateNotificationSettings({ ...notificationSettings, notificationType: type });
            setNotificationSettings({ ...notificationSettings, notificationType: type });
        };

        const setTimeZone = (value: string) => {
            updateNotificationSettings({ ...notificationSettings, timeZone: value });
            setNotificationSettings({ ...notificationSettings, timeZone: value });
        };

        const updateNotificationAlertTimes = (type: AlertTimeUpdateTypes, day: number, value: string | boolean) => {
            let newNotificationAlertTimes: AlertTime[] = [];
            notificationAlertTimes.forEach(val => newNotificationAlertTimes.push(Object.assign({}, val)));
            let alert = notificationAlertTimes.find(a => a.day === day);

            if (alert !== undefined) {
                if (value === 'allday') {
                    newNotificationAlertTimes[notificationAlertTimes.indexOf(alert)].allDay = true;
                } else {
                    switch (type) {
                        case 'endtime':
                            newNotificationAlertTimes[notificationAlertTimes.indexOf(alert)].allDay = false;
                            newNotificationAlertTimes[notificationAlertTimes.indexOf(alert)].endTime = value as string;
                            newNotificationAlertTimes[notificationAlertTimes.indexOf(alert)].end = value as string;
                            break;
                        case 'starttime':
                            newNotificationAlertTimes[notificationAlertTimes.indexOf(alert)].allDay = false;
                            newNotificationAlertTimes[notificationAlertTimes.indexOf(alert)].startTime = value as string;
                            newNotificationAlertTimes[notificationAlertTimes.indexOf(alert)].start = value as string;
                            break;
                        case 'enabled':
                            newNotificationAlertTimes[notificationAlertTimes.indexOf(alert)].enabled = value as boolean;
                            break;
                    }
                }
            }

            updateAlertTimes(newNotificationAlertTimes);
            setAlertTimes([...newNotificationAlertTimes]);
        };

        return (
            <TrackedPage
                docTitle="Notification Settings"
                title={
                    <Typography variant="h3" color="textPrimary" noWrap>
                        My Notification Settings
                    </Typography>
                }>
                <Grid container spacing={2}>
                    <Grid container item xs={12}>
                        <Card>
                            <SubCardContainer
                                title="Enable Notifications?"
                                desc={
                                    <Typography textAlign="left" variant="body2" color="textSecondary">
                                        Notifications provide key weather information to be sent to you.
                                    </Typography>
                                }>
                                <Grid container spacing={2}>
                                    <Grid item>
                                        <Switch
                                            checked={!notificationSettings.declinesNotifications}
                                            onChange={e => setDeclinesNotifications(e.target.checked)}
                                        />
                                    </Grid>
                                    <Grid item style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                                        {notificationSettings.declinesNotifications ? (
                                            <Label text="No, thanks" labelColor="textSecondary" />
                                        ) : (
                                            <Label text="Yes, please" labelColor="textSecondary" />
                                        )}
                                    </Grid>
                                </Grid>
                            </SubCardContainer>
                        </Card>
                    </Grid>

                    {!notificationSettings.declinesNotifications && (
                        <Grid container item md={5} sm={12}>
                            <Card>
                                <Grid container style={{ textAlign: 'left' }}>
                                    <Typography variant="subtitle1" color="textPrimary">
                                        Notification Schedule
                                    </Typography>
                                    <Typography variant="body1" color="textSecondary">
                                        Select the day and time that you’d prefer to receive weather related notifications
                                    </Typography>
                                </Grid>
                                {notificationAlertTimes.map(alertTime => (
                                    <div key={alertTime.id}>
                                        <hr style={{ margin: '12px 0' }} />
                                        <Grid container>
                                            <Grid item xs={10} style={{ textAlign: 'left', marginBottom: 8 }}>
                                                <Typography variant="subtitle1">{DaysOfWeek[alertTime.day - 1]}</Typography>
                                            </Grid>
                                            <Grid
                                                item
                                                xs={2}
                                                sm={false}
                                                style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                                                <Box display={{ sm: 'block', md: 'none' }}>
                                                    <Switch
                                                        checked={alertTime.enabled}
                                                        onChange={e => updateNotificationAlertTimes('enabled', alertTime.day, e.target.checked)}
                                                    />
                                                </Box>
                                            </Grid>
                                            <Grid container>
                                                {alertTime.allDay ? (
                                                    <Grid item md={4} xs={5}>
                                                        <Select
                                                            options={timeSelectionOptions}
                                                            value={alertTime.allDay ? 'allday' : alertTime.startTime}
                                                            disabled={!alertTime.enabled}
                                                            onChange={e => updateNotificationAlertTimes('starttime', alertTime.day, e)}
                                                        />
                                                    </Grid>
                                                ) : (
                                                    <>
                                                        <Grid item md={4} xs={5}>
                                                            <Select
                                                                options={timeSelectionOptions}
                                                                value={alertTime.allDay ? 'allday' : alertTime.startTime}
                                                                disabled={!alertTime.enabled}
                                                                onChange={e => updateNotificationAlertTimes('starttime', alertTime.day, e)}
                                                            />
                                                        </Grid>
                                                        <Grid
                                                            item
                                                            xs={1}
                                                            style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                                                            <span>&#8211;</span>
                                                        </Grid>
                                                        <Grid item md={4} xs={5}>
                                                            <Select
                                                                options={timeSelectionOptions}
                                                                value={alertTime.allDay ? 'allday' : alertTime.endTime}
                                                                disabled={!alertTime.enabled}
                                                                onChange={e => updateNotificationAlertTimes('endtime', alertTime.day, e)}
                                                            />
                                                        </Grid>
                                                    </>
                                                )}
                                                <Grid
                                                    item
                                                    sm={false}
                                                    md={2}
                                                    style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                                                    <Box display={{ xs: 'none', md: 'block' }}>
                                                        <Switch
                                                            checked={alertTime.enabled}
                                                            onChange={e => updateNotificationAlertTimes('enabled', alertTime.day, e.target.checked)}
                                                        />
                                                    </Box>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </div>
                                ))}
                            </Card>
                        </Grid>
                    )}

                    {!notificationSettings.declinesNotifications && (
                        <Grid item md={7} sm={12}>
                            <Card>
                                <SubCardContainer
                                    title="Daily Forecast Emails"
                                    desc={
                                        <Typography textAlign="left" variant="body2" color="textSecondary">
                                            Get daily emails about your selected location's forecast straight to your inbox.
                                        </Typography>
                                    }>
                                    <Grid container>
                                        <Grid item container spacing={2}>
                                            <Grid item>
                                                <Switch
                                                    checked={!notificationSettings.optOutForecast}
                                                    onChange={e => setDailyForecast(e.target.checked)}
                                                />
                                            </Grid>
                                            <Grid item style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                                                {notificationSettings.optOutForecast ? (
                                                    <Label text="No, thanks" labelColor="textSecondary" />
                                                ) : (
                                                    <Label text="Yes, please" labelColor="textSecondary" />
                                                )}
                                            </Grid>
                                        </Grid>
                                        {!notificationSettings.optOutForecast && (
                                            <Grid item xs={12} paddingTop="20px">
                                                <Select
                                                    value={userLocations?.find(x => x.forecastLocation)?.id}
                                                    options={userLocations ? userLocations.map(x => ({ text: x.label, value: x.id! })) : []}
                                                    onChange={updateForcastLocation}
                                                />
                                            </Grid>
                                        )}
                                    </Grid>
                                </SubCardContainer>
                                <hr style={{ margin: '24px 0' }} />
                                <SubCardContainer
                                    justifyTitle="flex-start"
                                    title="Alert Type"
                                    desc={
                                        <Typography textAlign="left" variant="body2" color="textSecondary">
                                            Get notified when lightning, precipitation, or severe weather exceeds your policy for a given location
                                        </Typography>
                                    }>
                                    <Grid container spacing={1}>
                                        {policyTriggers && policyTriggers.length > 0 && (
                                            <Grid item xs={12}>
                                                <Bread
                                                    icon={<InfoOutlined />}
                                                    variant="info"
                                                    header="Some alert types are locked by group policies."
                                                />
                                            </Grid>
                                        )}
                                        <Grid container item md sm={12}>
                                            {Array.from(new Set(policyTriggers?.map(x => x.policyTypeShortName))).map(policyShortName => {
                                                if (policyShortName) {
                                                    let policyAlertMap = getPolicyAlertsIcon(policyShortName, theme.palette.text.disabled);
                                                    if (policyAlertMap)
                                                        return (
                                                            <Grid item container xs={12} key={policyShortName}>
                                                                <Box display="flex" flexDirection="column" justifyContent="center" padding="9px">
                                                                    <Lock htmlColor={theme.palette.text.disabled} />
                                                                </Box>
                                                                <Label text={policyAlertMap.display} />
                                                                <Box justifyContent="center" flexDirection="column" display="flex" paddingLeft="16px">
                                                                    {policyAlertMap.icon}
                                                                </Box>
                                                            </Grid>
                                                        );
                                                    else return undefined;
                                                } else return undefined;
                                            })}
                                            <Grid item container xs={12}>
                                                <Checkbox
                                                    checked={notificationSettings.precipitationNotification}
                                                    onChange={e => setPrecipitationNotification(e.target.checked)}
                                                />
                                                <Label text="Precipitation Timing" />
                                                <Box justifyContent="center" flexDirection="column" display="flex" paddingLeft="16px">
                                                    <RainDropOutline color={theme.palette.action.inactive} />
                                                </Box>
                                            </Grid>
                                            <Grid item container xs={12}>
                                                <Checkbox
                                                    checked={notificationSettings.severeWeatherNotification}
                                                    onChange={e => setSevereWeatherNotification(e.target.checked)}
                                                />
                                                <Label text="NWS Alerts" />
                                                <Box justifyContent="center" flexDirection="column" display="flex" paddingLeft="16px">
                                                    <WarningAmberRounded htmlColor={theme.palette.action.inactive} />
                                                </Box>
                                            </Grid>
                                            <Grid item container xs={12}>
                                                <Checkbox
                                                    checked={notificationSettings.proactiveForecastNotification}
                                                    onChange={e => setProactiveForecastNotification(e.target.checked)}
                                                />
                                                <Label text="Perry Weather Forecasts" />
                                                <Box justifyContent="center" flexDirection="column" display="flex" paddingLeft="16px">
                                                    <CloudAlert fill={theme.palette.action.inactive} />
                                                </Box>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </SubCardContainer>
                                <hr style={{ margin: '24px 0' }} />
                                <SubCardContainer title="Notification Type" justifyTitle="flex-start">
                                    <Grid container spacing={1}>
                                        <RadioGroup
                                            value={notificationSettings.notificationType.toString()}
                                            onChange={e => setNotificationType(Number.parseInt(e.target.value))}>
                                            <Grid container item md sm={12}>
                                                <Grid item container md={12}>
                                                    <Grid container item>
                                                        <Radio value="0" />
                                                        <Label text="Push" />
                                                        <Box justifyContent="center" flexDirection="column" display="flex" paddingLeft="16px">
                                                            <BellRingOutline color={theme.palette.action.inactive} />
                                                        </Box>
                                                    </Grid>
                                                    <Grid container style={{ marginLeft: 42, textAlign: 'left' }}>
                                                        <Label labelColor="textSecondary" text="Get notifications pushed to your device" />
                                                    </Grid>
                                                </Grid>
                                                <Grid item container md={12}>
                                                    <Grid container item>
                                                        <Radio value="1" />
                                                        <Label text="Text Message (SMS)" />
                                                        <Box justifyContent="center" flexDirection="column" display="flex" paddingLeft="16px">
                                                            <CellphoneOutline color={theme.palette.action.inactive} />
                                                        </Box>
                                                    </Grid>
                                                    <Grid container style={{ marginLeft: 42, textAlign: 'left' }}>
                                                        <Box display="flex" flexDirection="column" justifyContent="center">
                                                            <Typography color="textSecondary" variant="body1">
                                                                Get notifications as a text message. Standard data rates may apply. See our&nbsp;
                                                                <a
                                                                    className={linkClass.link}
                                                                    rel="noreferrer"
                                                                    target="_blank"
                                                                    href="https://perryweather.com/sms-terms/">
                                                                    SMS Terms
                                                                </a>
                                                                &nbsp; and &nbsp;
                                                                <a
                                                                    className={linkClass.link}
                                                                    rel="noreferrer"
                                                                    target="_blank"
                                                                    href="https://perryweather.com/mobile-privacy/">
                                                                    privacy policy.
                                                                </a>
                                                            </Typography>
                                                        </Box>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </RadioGroup>
                                    </Grid>
                                </SubCardContainer>
                                <hr style={{ margin: '24px 0' }} />
                                <SubCardContainer
                                    title="Time Zone Preference"
                                    desc={
                                        <Typography textAlign="left" variant="body2" color="textSecondary">
                                            Use this setting to override the default timezone setting for your location.
                                        </Typography>
                                    }>
                                    <Grid container item md sm={12}>
                                        <Select value={notificationSettings.timeZone} options={timezones} onChange={setTimeZone} />
                                    </Grid>
                                </SubCardContainer>
                            </Card>
                        </Grid>
                    )}
                </Grid>
            </TrackedPage>
        );
    }
    return <></>;
}

export default NotificationSettings;
