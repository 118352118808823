import { Box, Typography } from "@mui/material";
import React from "react";
import { NWSAlert } from "../../../Services/API/advisoryApi";

interface NWSAlertTabContainerProps {
    nwsAlert: NWSAlert;
  }

export function NWSAlertTabContainer(props : NWSAlertTabContainerProps) {
    const { nwsAlert } = props;

    const formatDescription = (description: string) => {

        const sections = description?.split('\n\n');

        return sections?.map((section, index) => {
          const lines = section?.split('\n');

          return (
            <div key={index} style={{ marginBottom: '10px' }}>
              {lines?.map((line, lineIndex) => {
                const parts = line.split('...');

                if (parts.length > 1) {
                  return (
                    <Typography key={lineIndex} variant='body2'>
                      <strong>{parts[0]}...</strong> {parts.slice(1).join('...')}
                    </Typography>
                  );
                } else {
                  return (
                    <Typography key={lineIndex} variant='body2'>
                      {line}
                    </Typography>
                  );
                }
              })}
            </div>
          );
        });
      };

      return (
        <Box textAlign='left'>
            <Typography variant='h6'>NWS Alert</Typography>
            <hr />
            <Typography variant='subtitle1'>{nwsAlert.Headline}</Typography>
            <hr />
            {formatDescription(nwsAlert.Description)}
        </Box>
      )

}
