import { Grid, Box, Typography } from '@mui/material';
import { NotificationEventTypes, UserNotification } from '../../../Services/API';
import { useTheme } from '@mui/material/styles';
import WarningIcon from '../../../Assets/NotificationIcons/WarningIcon';
import LightningIcon from '../../../Assets/NotificationIcons/LightningIcon';
import WBGTIcon from '../../../Assets/NotificationIcons/WBGTIcon';
import HeatIcon from '../../../Assets/NotificationIcons/HeatIcon';
import WindChillIcon from '../../../Assets/NotificationIcons/WindChillIcon';
import PrecipIcon from '../../../Assets/NotificationIcons/PrecipIcon';
import WindIcon from '../../../Assets/NotificationIcons/WindIcon';
import AQIIcon from '../../../Assets/NotificationIcons/AQIIcon';
import SirenIcon from '../../../Assets/NotificationIcons/SirenIcon';
import { CloudAlert } from '../../../Assets/NotificationIcons/cloud-alert';

const { Other, Lightning, WBGT, HEAT, CHILL, PRECIP, WIND, GUST, AQI, SIREN, NWS, PROACTIVE_FORECAST } =
  NotificationEventTypes;
interface UserNotiItemProps {
  time: string;
  notification: UserNotification;
}

export default function UserNotiPreviewItem({ time, notification }: UserNotiItemProps) {
  const { message, eventType } = notification;
  const {
    palette: { secondary, notificationEventTypes },
  } = useTheme();

  const iconProps = { height: 20, width: 25 };
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const IconMapping: { [key in NotificationEventTypes]: () => React.ReactElement } = {
    [Other]: () => <WarningIcon {...iconProps} color={notificationEventTypes[Other]} />,
    [Lightning]: () => <LightningIcon {...iconProps} color={notificationEventTypes[Lightning]} />,
    [WBGT]: () => <WBGTIcon {...iconProps} color={notificationEventTypes[WBGT]} />,
    [HEAT]: () => <HeatIcon {...iconProps} color={notificationEventTypes[HEAT]} />,
    [CHILL]: () => <WindChillIcon {...iconProps} color={notificationEventTypes[CHILL]} />,
    [PRECIP]: () => <PrecipIcon {...iconProps} color={notificationEventTypes[PRECIP]} />,
    [WIND]: () => <WindIcon {...iconProps} color={notificationEventTypes[WIND]} />,
    [GUST]: () => <WindIcon {...iconProps} color={notificationEventTypes[GUST]} />,
    [AQI]: () => <AQIIcon {...iconProps} color={notificationEventTypes[AQI]} />,
    [SIREN]: () => <SirenIcon {...iconProps} color={notificationEventTypes[SIREN]} />,
    [NWS]: () => <WarningIcon {...iconProps} color={notificationEventTypes[NWS]} />,
    [PROACTIVE_FORECAST]: () => <CloudAlert {...iconProps} color={notificationEventTypes[PROACTIVE_FORECAST]} />,
  };

  const titleProps = { style: { fontSize: 14, fontWeight: 700 } };

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const TitleMapping: { [key in NotificationEventTypes]: () => React.ReactElement } = {
    [Other]: () => (
      <Typography {...titleProps} color={notificationEventTypes[Other]}>
        Notification
      </Typography>
    ),
    [Lightning]: () => (
      <Typography {...titleProps} color={notificationEventTypes[Lightning]}>
        Lightning Alert
      </Typography>
    ),
    [WBGT]: () => (
      <Typography {...titleProps} color={notificationEventTypes[WBGT]}>
        WBGT Alert
      </Typography>
    ),
    [HEAT]: () => (
      <Typography {...titleProps} color={notificationEventTypes[HEAT]}>
        Heat Index Alert
      </Typography>
    ),
    [CHILL]: () => (
      <Typography {...titleProps} color={notificationEventTypes[CHILL]}>
        Wind Chill Alert
      </Typography>
    ),
    [PRECIP]: () => (
      <Typography {...titleProps} color={notificationEventTypes[PRECIP]}>
        Precipitation Alert
      </Typography>
    ),
    [WIND]: () => (
      <Typography {...titleProps} color={notificationEventTypes[WIND]}>
        Wind Speed Alert
      </Typography>
    ),
    [GUST]: () => (
      <Typography {...titleProps} color={notificationEventTypes[GUST]}>
        Wind Gust Alert
      </Typography>
    ),
    [AQI]: () => (
      <Typography {...titleProps} color={notificationEventTypes[AQI]}>
        Air Quality Alert
      </Typography>
    ),
    [SIREN]: () => (
      <Typography {...titleProps} color={notificationEventTypes[SIREN]}>
        Siren Alert
      </Typography>
    ),
    [NWS]: () => (
      <Typography {...titleProps} color={notificationEventTypes[NWS]}>
        National Weather Service
      </Typography>
    ),
    [PROACTIVE_FORECAST]: () => (
      <Typography {...titleProps} color={notificationEventTypes[NWS]}>
        Perry Weather Forecast
      </Typography>
    ),
  };

  const Icon = IconMapping[eventType ?? Other] ?? IconMapping[Other];
  const Title = TitleMapping[eventType ?? Other] ?? TitleMapping[Other];

  return (
    <Box style={{ width: 300, whiteSpace: 'normal', padding: '6px 0px 6px 0px' }}>
      <Grid container>
        <Grid item container spacing={1} gap={1} direction='row' alignItems='center'>
          <Icon />
          <Title />
          <Typography style={{ flex: 1, textAlign: 'end' }} fontSize={12}>
            {time}
          </Typography>
        </Grid>
        <Grid item container>
          <Grid item xs={1} />
          <Grid item xs={11}>
            <Typography variant='body2' color={secondary.light}>
              {message}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
}
