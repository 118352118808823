import { useMemo, useRef, useState } from 'react';
import { Page } from '@perry-weather/component-library';
import { Grid, Skeleton, Typography } from '@mui/material';
import {
  NotificationEventTypes,
  POLICY_NOTIFICATION_TYPES,
  useNotificationsQuery,
  UserNotification,
} from '../Services/API';
import { Theme, useTheme } from '@mui/material/styles';
import moment from 'moment';
import { useProactiveForecastMessageQuery } from '../Services/API/proactiveForecastApi';
import { withClickTracking, withRenderTracking } from '../Components/Tracked/withMixpanelTracking';
import { TrackableEvents } from '../Components/Tracked/events';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import { Link } from '../Components/Base';

const TrackedPage = withRenderTracking(Page)(TrackableEvents.PageViewed, { page_name: 'Notifications' });
const TrackedLink = withClickTracking(Link)(TrackableEvents.ButtonClicked, {
  button_location: 'Notifications',
  button_feature: 'Notifications',
  button_type: 'link',
});

interface NotificationSection {
  notifications: UserNotification[];
  title: string;
  handleExpand: () => void;
  expanded: boolean;
}

function sliceNotifications(notifications: UserNotification[] | undefined, expanded: boolean) {
  if (!notifications) return [];
  return expanded ? notifications : notifications.slice(0, 3);
}

export function Notifications() {
  const endDate: Date = useMemo(() => new Date(), []);
  const startDate: Date = useMemo(() => new Date(new Date().setDate(endDate.getDate() - 7)), [endDate]);
  const { data, isLoading } = useNotificationsQuery({
    count: 20,
    startDate: startDate.toUTCString(),
    endDate: endDate.toUTCString(),
  });

  const sections: NotificationSection[] = [];

  const [nwsExpanded, setNwsExpanded] = useState(false);
  const [proactiveForecastExpanded, setProactiveForecastExpanded] = useState(false);
  const [policyExpanded, setPolicyExpanded] = useState(false);

  const nwsNotifications = sliceNotifications(
    data?.filter(notification => notification.eventType === NotificationEventTypes.NWS),
    nwsExpanded
  );
  const proactiveForecastNotifications = sliceNotifications(
    data?.filter(notification => notification.eventType === NotificationEventTypes.PROACTIVE_FORECAST),
    proactiveForecastExpanded
  );
  const policyNotifications = sliceNotifications(
    data?.filter(notification => POLICY_NOTIFICATION_TYPES.includes(notification.eventType)),
    policyExpanded
  );

  const handleNWSExpand = () => {
    setNwsExpanded(current => !current);
  };

  const handleProactiveForecastExpand = () => {
    setProactiveForecastExpanded(current => !current);
  };

  const handlePolicyExpand = () => {
    setPolicyExpanded(current => !current);
  };

  if (nwsNotifications && nwsNotifications?.length > 0) {
    sections.push({
      notifications: nwsNotifications,
      title: 'National Weather Service Alerts',
      handleExpand: handleNWSExpand,
      expanded: nwsExpanded,
    });
  }

  if (proactiveForecastNotifications && proactiveForecastNotifications?.length > 0) {
    const combinedNotifications = proactiveForecastNotifications.reduce<UserNotification[]>((acc, notification) => {
      const existingNotification = acc.find(n => n.correlationId === notification.correlationId);
      if (!existingNotification) {
        acc.push(notification);
      }
      return acc;
    }, []);

    sections.push({
      notifications: combinedNotifications,
      title: 'Perry Weather Forecasts',
      handleExpand: handleProactiveForecastExpand,
      expanded: proactiveForecastExpanded,
    });
  }

  if (policyNotifications && policyNotifications?.length > 0) {
    sections.push({
      notifications: policyNotifications,
      title: 'Policy Notifications',
      handleExpand: handlePolicyExpand,
      expanded: policyExpanded,
    });
  }

  return (
    <TrackedPage
      docTitle='My Notifications'
      title={
        <Typography variant='h3' color='textPrimary' noWrap>
          My Notifications
        </Typography>
      }
      subheader={
        <Typography variant='body1' color='textSecondary'>
          All notifications sent to your account in the past 7 days
        </Typography>
      }>
      <Grid container spacing={4} flexWrap={'nowrap'}>
        <Grid item container gap={2} xs={12}>
          {isLoading ? (
            <Grid item container gap={2} xs={12}>
              <Skeleton animation={false} variant='rectangular' width='100%' height={54} />
              <Skeleton animation={false} variant='rectangular' width='100%' height={54} />
              <Skeleton animation={false} variant='rectangular' width='100%' height={54} />
              <Skeleton animation={false} variant='rectangular' width='100%' height={54} />
            </Grid>
          ) : sections.length > 0 ? (
            sections.map(section => (
              <NotificationSection
                key={section.title}
                notifications={section.notifications}
                title={section.title}
                handleExpand={section.handleExpand}
                expanded={section.expanded}
              />
            ))
          ) : (
            <Grid item xs={12}>
              <Typography textAlign={'center'} variant='h6' color='textPrimary'>
                No notifications in the past 7 days
              </Typography>
            </Grid>
          )}
        </Grid>
      </Grid>
    </TrackedPage>
  );
}

function NotificationSection(props: NotificationSection) {
  const { notifications } = props;
  const theme = useTheme();

  return (
    <Grid item container gap={1} xs={12}>
      <NotificationSectionHeader title={props.title} handleExpand={props.handleExpand} expanded={props.expanded} />
      {notifications.map(notification => (
        <NotificationRow key={notification.id} theme={theme} notification={notification} />
      ))}
    </Grid>
  );
}
function NotificationRow(props: { theme: Theme; notification: UserNotification }) {
  const { theme, notification } = props;
  const { data: proactiveForecastMessage } = useProactiveForecastMessageQuery(notification.correlationId || skipToken, {
    skip: notification.eventType !== NotificationEventTypes.PROACTIVE_FORECAST,
  });

  const { message, sentUtc } = notification;
  const sentDate = moment.utc(sentUtc).local().format('MM/DD/YYYY HH:mm A') + ' ' + notification.timeZone;

  const [isExpanded, setIsExpanded] = useState(false);
  const messageRef = useRef<HTMLDivElement>(null);

  const handleExpand = () => {
    setIsExpanded(current => !current);
  };

  const displayMessage = proactiveForecastMessage?.message.replaceAll('<br>', '\n') ?? message;
  return (
    <Grid
      item
      key={notification.id}
      container
      sx={{
        backgroundColor: theme.palette.background.paper,
        border: '1px solid',
        borderColor: theme.palette.border.main,
        borderRadius: 2,
        '&:hover': {
          backgroundColor: theme.palette.action.hoverSecondary,
        },
      }}
      onClick={handleExpand}
      paddingX={2}
      paddingY={2}
      xs={12}>
      <Grid container gap={2} item xs={12} flexDirection={'row'} justifyContent={'space-between'} flexWrap={'nowrap'}>
        <Grid container item xs={9} flexDirection={'row'}>
          <Grid xs={12} item>
            <Typography ref={messageRef} textAlign={'left'} variant='body1' color='textPrimary' noWrap>
              {message}
            </Typography>
          </Grid>
        </Grid>
        <Grid xs={2} item>
          <Typography textAlign={'left'} variant='body1' color='textPrimary' noWrap>
            {sentDate}
          </Typography>
        </Grid>
      </Grid>
      {isExpanded && (
        <Grid item xs={12}>
          <hr />
          <Typography whiteSpace={'pre-line'} textAlign={'left'} variant='body1' color='textPrimary'>
            {displayMessage}
          </Typography>
        </Grid>
      )}
    </Grid>
  );
}

function NotificationSectionHeader(props: { title: string; handleExpand: () => void; expanded: boolean }) {
  const expandLabel = props.expanded ? 'Collapse' : 'Expand';
  return (
    <Grid
      item
      container
      flexDirection={'row'}
      justifyContent={'space-between'}
      alignItems={'center'}
      xs={12}
      marginTop={4}>
      <Typography textAlign={'left'} variant='h6' color='textPrimary' noWrap>
        {props.title}
      </Typography>
      <TrackedLink onClick={props.handleExpand} mpExtraData={{ button_label: expandLabel }}>
        <Typography variant='body1'>{expandLabel}</Typography>
      </TrackedLink>
    </Grid>
  );
}

export default Notifications;
